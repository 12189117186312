import React, { FC } from 'react'
import { IDENTITY_DOCUMENT_LOCALIZATION } from './IdentityDocument.localization'
import { Col, Row } from 'antd'
import { FieldView } from 'components/controls/FieldView'
import { useGetProfile } from 'hooks/useGetProfile'
import { formatDate } from 'mmc-react-shared'
import {
    CITIZEN_DOC_GRID_COL,
    CITIZEN_DOC_GRID_ROW,
} from 'pages/CitizenCardDocument/CitizenCardDocument.consts'
import { DocumentScanView } from 'components/controls/DocumentScanView'
import { APP_LOCALIZATION } from 'localization'
import { hasDocScanViewPermission } from 'utils/permissions.utils'
import { useGetApiUserQuery } from 'api/generator/appApi'
import { CitizenCardHeader } from 'components/business/CitizenCardHeader'
import { getDateColorByExpiration } from 'pages/CitizenCardDocument/CitizenCardDocument.utils'

export const IdentityDocument: FC = React.memo(() => {
    const { profile } = useGetProfile()
    const { data: user } = useGetApiUserQuery()

    const document = profile?.identityDocs?.[0] || {}

    const typeDocument =
        document.identityDocumentType &&
        APP_LOCALIZATION.DICTIONARIES.IDENTITY_DOCUMENT_TYPES[
            document.identityDocumentType
        ]

    return (
        <>
            <CitizenCardHeader title={IDENTITY_DOCUMENT_LOCALIZATION.TITLE} />

            <Row {...CITIZEN_DOC_GRID_ROW}>
                <Col {...CITIZEN_DOC_GRID_COL}>
                    <FieldView
                        label={IDENTITY_DOCUMENT_LOCALIZATION.TYPE_DOCUMENT}
                        value={typeDocument}
                    />
                </Col>

                <Col xs={12} md={6}>
                    <FieldView
                        label={IDENTITY_DOCUMENT_LOCALIZATION.ISSUE_DATE}
                        value={formatDate(document.issuedAt)}
                    />
                </Col>

                <Col xs={12} md={6}>
                    <FieldView
                        label={IDENTITY_DOCUMENT_LOCALIZATION.EXPIRES_DATE}
                        value={formatDate(document.expirationDate)}
                        valueColor={getDateColorByExpiration(document.isExpired)}
                    />
                </Col>

                <Col xs={24}>
                    <FieldView
                        label={IDENTITY_DOCUMENT_LOCALIZATION.ISSUED_BY}
                        value={document.issuer}
                    />
                </Col>

                <Col xs={12} md={6}>
                    <FieldView
                        label={IDENTITY_DOCUMENT_LOCALIZATION.COUNTRY_OF_BIRTH}
                        value={profile?.countryOfBirth?.name}
                    />
                </Col>

                <Col xs={12} md={6}>
                    <FieldView
                        label={IDENTITY_DOCUMENT_LOCALIZATION.PLACE_OF_BIRTH}
                        value={profile?.placeOfBirth}
                    />
                </Col>

                {hasDocScanViewPermission(
                    user?.permissions,
                    'IdentityDocumentScanView'
                ) && (
                    <Col xs={24}>
                        <DocumentScanView files={document.files} />
                    </Col>
                )}
            </Row>
        </>
    )
})
