export const PATENT_INFORMATION_LOCALIZATION = {
    PATENT: 'Патент',
    SERIES_NUMBER: 'Серия и номер',
    ISSUE_DATE: 'Дата выдачи',
    EXPIRES_DATE: 'Действителен до',
    PAID_UNTIL_DATE: 'Оплачен до',
    PROFESSION: 'Профессия',
    ISSUED_BY: 'Кем выдан',
    LICENSE_TERRITORY: 'Территория действия',
}
