import { Layout } from 'components/layouts/Layout'
import React, { FC } from 'react'

export const NotFound: FC = React.memo(() => {
    return (
        <Layout className="flex items-center justify-center">
            <div>404</div>
        </Layout>
    )
})
