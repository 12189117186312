import { memo, useCallback, useEffect, useState } from 'react'

import { Document, Page } from 'react-pdf'
import { Preloader } from 'mmc-react-shared'
import { pdfWorkerHelper } from 'helpers/PdfWorkerHelper'

import { FullscreenPdfProps } from './FullscreenPdf.types'

import styles from './FullscreenPdf.module.css'

import 'react-pdf/dist/Page/TextLayer.css'
import { Typography } from 'mmc-react-theme'

pdfWorkerHelper.load()

export const FullscreenPdf: React.FC<FullscreenPdfProps> = memo(
    ({ file, width, rotate, scale }) => {
        const [isPdfLoading, setPdfLoading] = useState(false)

        const [totalPages, setTotalPages] = useState<number>()

        const handleLoadSuccess = useCallback(
            ({ numPages }: { numPages: number }) => {
                setTotalPages(numPages)

                setPdfLoading(false)
            },
            [setTotalPages]
        )

        const handleLoadError = useCallback(
            (e: Error) => {
                setPdfLoading(false)

                console.log('Ошибка загрузки документа: ', e)
            },
            [setPdfLoading]
        )

        useEffect(() => {
            setPdfLoading(true)
        }, [])

        const listOfPages = [...Array(totalPages).keys()].map((page) => ++page)

        return (
            <div className="overflow-auto">
                <Preloader
                    isLoading={isPdfLoading}
                    loaderType="white"
                    positionCenter
                    loaderFixed
                />

                <Document
                    file={file}
                    onLoadSuccess={handleLoadSuccess}
                    onLoadError={handleLoadError}
                    rotate={rotate}
                    loading=""
                    className={styles.root}
                >
                    {listOfPages.map((page) => (
                        <Page
                            className="mb-2 relative"
                            scale={scale}
                            width={width}
                            renderTextLayer={false}
                            renderAnnotationLayer={false}
                            pageNumber={page}
                            key={page}
                        >
                            <Typography
                                text={`${page}/${listOfPages.length}`}
                                style={{
                                    fontSize: `calc(100vw * 0.02 * ${scale})`,
                                    padding: `calc(100vw * 0.02 * ${scale})`,
                                }}
                                className={styles.pdfHeader}
                                leading="1"
                            />
                        </Page>
                    ))}
                </Document>
            </div>
        )
    }
)
