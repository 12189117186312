import { FC } from 'react'
import { PersonalInformationProps } from './PersonalInformation.types'
import { PERSONAL_INFORMATION_LOCALIZATION } from './PersonalInformation.localization'
import { CitizenFIO } from 'components/business/CitizenFIO'
import { Attention } from 'components/common/Attention'
import { InfoFields } from 'components/business/InfoFields'
import classNames from 'classnames'
import { PersonInformationPhoto } from './PersonInformationPhoto'
import styles from './PersonalInformation.module.css'
import { TEST_IDS } from 'consts/tests.consts'

export const PersonalInformationViewTablet: FC<PersonalInformationProps> = ({
    name,
    surname,
    patronymic,
    citizenship,
    birthdate,
    gender,
    photoSrc,
    compactView,
    attention,
    testingDocs,
    patentDocs,
    biometryFingerprintDocs,
    identityDocs,
}) => {
    return (
        <div
            className={classNames(styles.root, 'p-6 flex bg-color-neutral-1')}
            data-testid={TEST_IDS.PERSONAL_INFORMATION_VIEW_TABLET}
        >
            <PersonInformationPhoto photoSrc={photoSrc} compactView={compactView} />

            <div className={classNames('flex-grow', compactView ? 'ps-6' : 'ps-12')}>
                <CitizenFIO
                    className={classNames(
                        styles.FIOTablet,
                        compactView ? 'mb-3' : 'mb-6'
                    )}
                    name={name}
                    surname={surname}
                    patronymic={patronymic}
                />

                {attention && (
                    <Attention
                        className="mb-6"
                        text={PERSONAL_INFORMATION_LOCALIZATION.ATTENTION}
                    />
                )}

                <InfoFields
                    className={classNames(compactView && 'hidden')}
                    citizenship={citizenship?.name}
                    birthdate={birthdate}
                    gender={gender}
                    patentDocs={patentDocs}
                    testingDocs={testingDocs}
                    biometryFingerprintDocs={biometryFingerprintDocs}
                    identityDocs={identityDocs}
                />
            </div>
        </div>
    )
}
