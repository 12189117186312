import { TEST_IDS } from 'consts/tests.consts'
import {
    FieldView as FieldViewOrigin,
    FieldViewProps,
    getDynamicTestId,
} from 'mmc-react-shared'
import { FC } from 'react'

export const FieldView: FC<FieldViewProps> = (props) => (
    <FieldViewOrigin
        labelColor="color-neutral-13"
        valuePlaceholder="-"
        dataTestId={getDynamicTestId(TEST_IDS.FIELD_VIEW, props.label)}
        {...props}
        value={props.value?.toString().trim()}
    />
)
