import { memo, useCallback, useMemo, useRef, useState } from 'react'

import { StoreValue } from 'mmc-react-shared'
import { Icon } from 'mmc-react-icons'
import { Typography } from 'mmc-react-theme'

import { useSize } from 'hooks/useSize'
import { downloadFile, getFileUrl } from 'utils/file.utils'

import { FullscreenPdf } from '../FullscreenPdf'
import { DocumentFullscreenProps } from '../../DocumentFullscreen.types'
import { FullscreenCanvasTopControls } from '../FullscreenCanvasTopControls'

import { CANVAS_PARAMS } from './FullscreenCanvas.consts'

import styles from './FullscreenCanvas.module.css'
import { FULLSCREEN_CANVAS_LOCALIZATION } from './FullscreenCanvas.localization'

export const FullscreenCanvas: React.FC<DocumentFullscreenProps> = memo(
    ({ files, currentFileIndex, onClose, prevFullscreen, nextFullscreen }) => {
        const [rotate, setRotate] = useState(CANVAS_PARAMS.ROTATE_INITIAL)

        const [scale, setScale] = useState(CANVAS_PARAMS.SCALE_INITIAL)

        const [touchStart, setTouchStart] = useState(CANVAS_PARAMS.INITIAL_TOUCH_START)

        const [touchEnd, setTouchEnd] = useState(CANVAS_PARAMS.INITIAL_TOUCH_END)

        const target = useRef<HTMLDivElement>(null)

        const { width } = useSize(target)

        const { fileType, id } = files?.[currentFileIndex] || {}

        const handleDocxClick = useCallback(() => {
            downloadFile(files?.[currentFileIndex])
        }, [currentFileIndex, files])

        const resetCanvas = useCallback(() => {
            setRotate(CANVAS_PARAMS.ROTATE_INITIAL)

            setScale(CANVAS_PARAMS.SCALE_INITIAL)

            setTouchStart(CANVAS_PARAMS.INITIAL_TOUCH_START)

            setTouchEnd(CANVAS_PARAMS.INITIAL_TOUCH_END)
        }, [])

        const handlePrevFullScreen = useCallback(() => {
            prevFullscreen()

            resetCanvas()
        }, [prevFullscreen, resetCanvas])

        const handleNextFullScreen = useCallback(() => {
            nextFullscreen()

            resetCanvas()
        }, [nextFullscreen, resetCanvas])

        const handleRotate = useCallback(() => {
            setRotate((currentRotate) => {
                return (
                    (Math.abs(currentRotate) === CANVAS_PARAMS.ROTATE_MAX
                        ? CANVAS_PARAMS.ROTATE_INITIAL
                        : currentRotate) - CANVAS_PARAMS.ROTATE_STEP
                )
            })
        }, [])

        const handleZoomIn = useCallback(() => {
            setScale((scale) => {
                return scale + CANVAS_PARAMS.SCALE_STEP
            })
        }, [])

        const handleZoomOut = useCallback(() => {
            setScale((scale) => {
                return scale <= CANVAS_PARAMS.SCALE_STEP
                    ? CANVAS_PARAMS.SCALE_STEP
                    : scale - CANVAS_PARAMS.SCALE_STEP
            })
        }, [])

        const handleTouchStart = (e: StoreValue) => {
            setTouchStart(e.targetTouches[0].clientX)
        }

        const handleTouchMove = (e: StoreValue) => {
            setTouchEnd(e.targetTouches[0].clientX)
        }

        const handleTouchEnd = () => {
            if (touchEnd && touchStart - touchEnd > CANVAS_PARAMS.LEFT_SWIPE_IDENTITY) {
                handleNextFullScreen()
            }

            if (touchStart - touchEnd < CANVAS_PARAMS.RIGHT_SWIPE_IDENTITY) {
                handlePrevFullScreen()
            }
        }

        const isRotated = CANVAS_PARAMS.ROTATED_DEGS.includes(Math.abs(rotate))

        const isScaled = scale !== CANVAS_PARAMS.SCALE_INITIAL

        const viewWidth = width ? width * scale : undefined

        const previewRender = useMemo(() => {
            const src = getFileUrl(id)

            switch (fileType) {
                case 'Pdf':
                    return (
                        <FullscreenPdf
                            file={src}
                            width={width}
                            rotate={rotate}
                            scale={scale}
                        />
                    )
                case 'Jpeg':
                case 'Png':
                    return (
                        <div className="overflow-auto">
                            <img
                                src={src}
                                style={{
                                    transform: `rotate(${rotate}deg)`,
                                    width: viewWidth,
                                    height: isRotated ? viewWidth : undefined,
                                    objectFit: isRotated ? 'contain' : undefined,
                                    objectPosition: isRotated ? 'center' : undefined,
                                }}
                            />
                        </div>
                    )
                case 'Docx':
                    return (
                        <div className="bg-color-neutral-1 p-4 w-full">
                            <div onClick={handleDocxClick} className={styles.docx}>
                                <Icon
                                    svgType="SvgIconFileDownload16X20"
                                    iconContainerPreset="32"
                                />

                                <Typography>
                                    {FULLSCREEN_CANVAS_LOCALIZATION.DOCX}
                                </Typography>
                            </div>
                        </div>
                    )
            }
        }, [id, fileType, width, rotate, scale, viewWidth, isRotated, handleDocxClick])

        return (
            <div className={styles.root}>
                <FullscreenCanvasTopControls
                    currentFileIndex={currentFileIndex}
                    fileType={fileType}
                    totalFiles={files?.length}
                    onClose={onClose}
                    onNextFullScreen={handleNextFullScreen}
                    onPrevFullScreen={handlePrevFullScreen}
                    onRotate={handleRotate}
                    onZoomIn={handleZoomIn}
                    onZoomOut={handleZoomOut}
                    resetCanvas={resetCanvas}
                />

                <div
                    ref={target}
                    className="px-4 flex justify-center overflow-auto"
                    onTouchStart={isScaled ? undefined : handleTouchStart}
                    onTouchEnd={isScaled ? undefined : handleTouchEnd}
                    onTouchMove={isScaled ? undefined : handleTouchMove}
                >
                    {previewRender}
                </div>
            </div>
        )
    }
)
