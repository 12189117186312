import { APP_LOCALIZATION } from 'localization'
import { formatDate } from 'mmc-react-shared'

export const formatDateWithExpiration = (expirationDate?: string | null) => {
    if (!expirationDate) return

    return `${APP_LOCALIZATION.DATE.EXPIRE_DATE} - ${formatDate(expirationDate)}`
}

export const formatDateWithYearOfChange = (expirationDate?: number | null) => {
    if (!expirationDate) return

    return `${APP_LOCALIZATION.DATE.YEAR_OF_CHANGE} - ${expirationDate}`
}
