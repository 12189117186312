import { memo } from 'react'

import dayjs from 'dayjs'
import { Col, Row } from 'antd'
import { formatDate } from 'mmc-react-shared'

import { FieldView } from 'components/controls/FieldView'
import { PatentInformationProps } from './PatentInformation.types'
import {
    CITIZEN_DOC_GRID_COL,
    CITIZEN_DOC_GRID_ROW,
} from 'pages/CitizenCardDocument/CitizenCardDocument.consts'

import { PATENT_INFORMATION_LOCALIZATION } from './PatentInformation.localization'
import { getSeriesAndNumber } from 'utils/common.utils'
import { Typography } from 'mmc-react-theme'

export const PatentInformation: React.FC<PatentInformationProps> = memo(({ patent }) => {
    const isPatentPaid = patent?.paymentInfo?.isPaid

    const patentPaidUntil = patent?.paymentInfo?.paidUntil

    const isPatentExpired = patent?.isExpired

    return (
        <>
            <Typography type="heading-6" weight="600" className="flex items-center mb-3">
                {PATENT_INFORMATION_LOCALIZATION.PATENT}
            </Typography>

            <Row gutter={CITIZEN_DOC_GRID_ROW.gutter}>
                <Col md={6} xs={12}>
                    <FieldView
                        label={PATENT_INFORMATION_LOCALIZATION.SERIES_NUMBER}
                        value={getSeriesAndNumber(patent)}
                    />
                </Col>

                <Col md={6} xs={12}>
                    <FieldView
                        label={PATENT_INFORMATION_LOCALIZATION.ISSUE_DATE}
                        value={formatDate(patent?.issuedAt)}
                    />
                </Col>

                <Col {...CITIZEN_DOC_GRID_COL}>
                    <FieldView
                        label={PATENT_INFORMATION_LOCALIZATION.EXPIRES_DATE}
                        value={`${formatDate(dayjs(patent?.expirationDate))}`}
                        valueColor={isPatentExpired ? 'color-red-9' : 'color-neutral-19'}
                    />
                </Col>

                <Col {...CITIZEN_DOC_GRID_COL}>
                    <FieldView
                        label={PATENT_INFORMATION_LOCALIZATION.PAID_UNTIL_DATE}
                        value={formatDate(patentPaidUntil)}
                        valueColor={isPatentPaid ? 'color-neutral-19' : 'color-red-9'}
                    />
                </Col>

                <Col xs={24} md={12}>
                    <FieldView
                        label={PATENT_INFORMATION_LOCALIZATION.ISSUED_BY}
                        value={patent?.issuer}
                    />
                </Col>

                <Col xs={24} md={12}>
                    <FieldView
                        label={PATENT_INFORMATION_LOCALIZATION.LICENSE_TERRITORY}
                        value={patent?.region}
                    />
                </Col>
            </Row>
        </>
    )
})
