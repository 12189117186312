import React, { FC } from 'react'
import { LayoutProps, Layout as LayoutShared } from 'mmc-react-shared'
import styles from './Layout.module.css'
import classNames from 'classnames'

export const Layout: FC<LayoutProps> = React.memo((props) => (
    <div className={styles.root}>
        <div className={styles.content}>
            <LayoutShared
                {...props}
                mainClassName={classNames(styles.main, props.mainClassName)}
            />
        </div>
    </div>
))
