import { AccountPermissionContract } from 'api/generator/appApi'
import { hasPermissions, hasSomePermission } from 'mmc-react-shared'

export const hasDocsViewPermissions = (
    userPermissions?: AccountPermissionContract[] | null
) => {
    return hasPermissions(userPermissions, ['BasicView', 'DocsView'])
}

export const hasSomeDocsViewPermission = (
    userPermissions?: AccountPermissionContract[] | null
) => {
    return (
        hasDocsViewPermissions(userPermissions) &&
        hasSomePermission(userPermissions, [
            'DocsAllView',
            'ArrivalNotificationView',
            'DmsView',
            'BiometryFingerprintDocumentView',
            'ForeignCardView',
            'HivCertificateView',
            'IdentityDocumentView',
            'InnView',
            'MedicalReportView',
            'MigrationCardView',
            'PreviousFioView',
            'PsychiatricReportView',
            'ResidencePermitView',
            'TemporaryResidencePermitView',
            'TestingView',
        ])
    )
}

export const hasDocScanViewPermission = (
    userPermissions?: AccountPermissionContract[] | null,
    scanViewPermission?: AccountPermissionContract
) => {
    return (
        hasDocsViewPermissions(userPermissions) &&
        (hasPermissions(userPermissions, 'ScansAllView') ||
            hasPermissions(userPermissions, scanViewPermission))
    )
}

export const hasSingleDocViewPermission = (
    userPermissions?: AccountPermissionContract[] | null,
    permission?: AccountPermissionContract
) => {
    return (
        hasDocsViewPermissions(userPermissions) &&
        (hasPermissions(userPermissions, 'DocsAllView') ||
            hasPermissions(userPermissions, permission))
    )
}

export const hasLaborViewPermissions = (
    userPermissions?: AccountPermissionContract[] | null
) => {
    return hasPermissions(userPermissions, ['BasicView', 'LaborView'])
}

export const hasPersonaViewPermissions = (
    userPermissions?: AccountPermissionContract[] | null
) => {
    return hasPermissions(userPermissions, ['BasicView', 'PersonaView'])
}

export const hasContactViewPermissions = (
    userPermissions?: AccountPermissionContract[] | null
) => {
    return hasPermissions(userPermissions, ['BasicView', 'ContactView'])
}

export const hasAppealViewPermissions = (
    userPermissions?: AccountPermissionContract[] | null
) => {
    return hasPermissions(userPermissions, ['BasicView', 'AppealView'])
}
