export const APP_INIT_LOCALIZATION = {
    MONTHS_SHORT: [
        'янв',
        'фев',
        'мар',
        'апр',
        'мая',
        'июн',
        'июл',
        'авг',
        'сен',
        'окт',
        'ноя',
        'дек',
    ],
}
