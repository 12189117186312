import { VITE_FILE_PATH } from 'consts/env.consts'
import { UploadedFileContract } from 'api/generator/appApi'
import {
    getFileUrl as getFileUrlShared,
    downloadFile as downloadFileShared,
} from 'mmc-react-shared'

export const getFileUrl = (file?: null | number | string) =>
    getFileUrlShared(VITE_FILE_PATH, file)

export const downloadFile = (file?: UploadedFileContract) => {
    if (!file?.id) return

    const fileSrc = getFileUrl(file.id)

    const fileName = `${`Документ_для_загрузки.${file.fileType}`}`

    return downloadFileShared(fileSrc, fileName)
}
