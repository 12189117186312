import dayjs from 'dayjs'
import { isDateValid, formatDate, joinTrue } from 'mmc-react-shared'

interface GetSeriesAndNumberProps {
    series?: string | null
    number?: string | null
}

export const getSeriesAndNumber = <T extends GetSeriesAndNumberProps>(
    props?: T,
    separator?: string
) => {
    if (!props) return

    const { series, number } = props

    return joinTrue([series, number], separator)
}

interface GetIssuedAtProps {
    issuedAt?: string | null
}

export const getIssuedAt = <T extends GetIssuedAtProps>(props?: T) => {
    if (!props) return

    const { issuedAt } = props

    if (!isDateValid(issuedAt)) return

    return `от ${formatDate(issuedAt)}`
}

interface GetIssuerProps {
    issuer?: string | null
}

export const getIssuer = <T extends GetIssuerProps>(props?: T) => {
    if (!props) return

    const { issuer } = props

    if (!issuer) return

    return `выдано ${issuer}`
}

export const authLogout = () => {
    window.location.href = '/profile-registry/auth/logout'
}

interface GetLatestDocProps {
    issuedAt?: string | null
}

export const getLatestDoc = <T extends GetLatestDocProps>(docs?: Array<T> | null) => {
    if (!docs) return

    const tempDocs = [...docs]

    tempDocs.sort((a, b) => {
        return dayjs(b.issuedAt).unix() - dayjs(a.issuedAt).unix()
    })

    return tempDocs[0]
}
