import { ReactNode } from 'react'
import { CollapseLabel } from 'components/common/Collapse'
import { CollapseExtra } from 'components/common/Collapse/CollapseExtra'
import { CitizenPersonalData } from './components/CitizenPersonalData'
import { CITIZEN_CARD_LOCALIZATION } from './CitizenCard.localization'
import { CitizenContactsData } from './components/CitizenContactsData'
import { ROUTES } from 'routing/routes'
import some from 'lodash/some'
import {
    GetApiProfileByCardApiResponse,
    HierarchyItemContract,
    HierarchyItemTypesContract,
    PatentDocumentWithFilesInfoContract,
    UserContract,
} from 'api/generator/appApi'
import { formatDate, getDataIfTrue, getJsxIfTrue } from 'mmc-react-shared'
import { CitizenDocuments } from './components/CitizenDocuments'
import { CitizenPatents } from './components/CitizenPatents'
import { getSeriesAndNumber } from 'utils/common.utils'
import { CommonDocumentProps } from './CitizenCard.types'
import { ShortDocumentInfo } from './components/CitizenDocuments/CitizenDocuments.types'
import { formatDateWithExpiration, formatDateWithYearOfChange } from 'utils/date.utils'
import { HierarchyType, StoreUnionKeyPartial } from 'types/common.types'
import { ItemType } from '../../../node_modules/rc-collapse/es/interface.d'
import { APP_LOCALIZATION } from 'localization'
import { APPEAL_ATTENTION_STATUSES } from './CitizenCard.consts'
import { CitizenAppeals } from './components/CitizenAppeals'
import { CitizenDataPlaceholder } from './components/CitizenDataPlaceholder'
import includes from 'lodash/includes'
import {
    hasAppealViewPermissions,
    hasContactViewPermissions,
    hasSomeDocsViewPermission,
    hasDocsViewPermissions,
    hasPersonaViewPermissions,
    hasSingleDocViewPermission,
    hasLaborViewPermissions,
} from 'utils/permissions.utils'

const mergeDataShemeAndHierarchy = <T,>(
    hierarchy?: HierarchyItemContract[],
    data?: StoreUnionKeyPartial<HierarchyItemTypesContract, T | undefined>
) => {
    if (!hierarchy || !data) return

    const result = hierarchy.reduce<T[]>((acc, { isHidden, itemName }) => {
        if (isHidden || !itemName || !data[itemName]) return acc

        return [...acc, data[itemName] as T]
    }, [])

    return result
}

/**
 * Флаг - есть ли неоплаченные патенты
 * @param patents - патенты
 * @returns true/false
 */
export const hasUnpaidPatents = (
    patents?: PatentDocumentWithFilesInfoContract[] | null
) => patents?.some((patent) => !patent.paymentInfo?.isPaid)

export const getCitizenCardCollapseScheme = (
    profile?: GetApiProfileByCardApiResponse,
    hierarchyBaseInfoAndDocs?: HierarchyType,
    userPermissions?: UserContract['permissions'],
    patentPayments?: PatentDocumentWithFilesInfoContract[] | null,
    excludeKeys?: string[]
) => {
    if (!profile || !hierarchyBaseInfoAndDocs) return []

    const {
        surname,
        name,
        patronymic,
        surnameLatin,
        nameLatin,
        patronymicLatin,
        birthdate,
        citizenship,
        countryOfBirth,
        placeOfBirth,
        addressesMatched,
        registrationAddress,
        residenceAddress,
        phoneNumber,
        additionalPhoneNumber,
        email,
        gender,
        patentDocs,
        appeals,
        ...commonDocuments
    } = profile

    const personal = {
        surname,
        name,
        patronymic,
        surnameLatin,
        nameLatin,
        patronymicLatin,
        gender: gender && APP_LOCALIZATION.DICTIONARIES.GENDER[gender],
        birthdate: formatDate(birthdate),
        citizenship: citizenship?.name,
        countryOfBirth: countryOfBirth?.name,
        placeOfBirth: placeOfBirth,
        attention: false,
    }

    const contacts = {
        registrationAddress,
        residenceAddress,
        addressesMatched,
        phoneNumber,
        additionalPhoneNumber,
        email,
        attention: false,
    }

    const documentsList = getDocumentsList(
        commonDocuments,
        hierarchyBaseInfoAndDocs.documents,
        userPermissions
    )

    const isAppealsAttentionVisible = appeals?.some((appeal) =>
        includes(APPEAL_ATTENTION_STATUSES, appeal.statusCode)
    )

    const isDocumentsAttentionVisible = documentsList?.some(
        (document) => document.attention
    )

    const getChildrenIfTrue = (condition: unknown, jsx: ReactNode) =>
        getJsxIfTrue(
            condition,
            jsx,
            <CitizenDataPlaceholder>
                {APP_LOCALIZATION.PLACEHOLDERS.MISSING_DATA}
            </CitizenDataPlaceholder>
        )

    const baseInfoCollapseScheme: StoreUnionKeyPartial<
        HierarchyItemTypesContract,
        ItemType | undefined
    > = {
        Documents: getDataIfTrue(hasDocsViewPermissions(userPermissions), {
            key: ROUTES['citizen-card/:uidOrCard'].documents._relative,
            label: (
                <CollapseLabel
                    title={CITIZEN_CARD_LOCALIZATION.DOCUMENTS_TITLE}
                    svgIconType="SvgIconFolderOutline20X16"
                />
            ),
            extra: isDocumentsAttentionVisible && (
                <CollapseExtra svgIconType="SvgIconAlertCircleFill16X16" />
            ),
            children: hasSomeDocsViewPermission(userPermissions) ? (
                getChildrenIfTrue(
                    some(documentsList, 'value'),
                    <CitizenDocuments documents={documentsList as ShortDocumentInfo[]} />
                )
            ) : (
                <CitizenDataPlaceholder>
                    {APP_LOCALIZATION.PLACEHOLDERS.PERMISSION_DENIED}
                </CitizenDataPlaceholder>
            ),
        }),
        Labour: getDataIfTrue(hasLaborViewPermissions(userPermissions), {
            key: ROUTES['citizen-card/:uidOrCard'].patents._relative,
            label: (
                <CollapseLabel
                    title={CITIZEN_CARD_LOCALIZATION.WORK_DATA_TITLE}
                    svgIconType="SvgIconWorkOutline20X19"
                />
            ),
            extra: hasUnpaidPatents(patentPayments) && (
                <CollapseExtra svgIconType="SvgIconAlertCircleFill16X16" />
            ),
            children: getChildrenIfTrue(
                some(patentDocs),
                <CitizenPatents patentDocs={patentDocs} />
            ),
        }),
        PersonalInfo: getDataIfTrue(hasPersonaViewPermissions(userPermissions), {
            key: ROUTES['citizen-card/:uidOrCard'].personal._relative,
            label: (
                <CollapseLabel
                    title={CITIZEN_CARD_LOCALIZATION.PERSONAL_DATA_TITLE}
                    svgIconType="SvgIconPersonOutline16X16"
                />
            ),
            extra: personal?.attention && (
                <CollapseExtra svgIconType="SvgIconAlertCircleFill16X16" />
            ),
            children: getChildrenIfTrue(
                some(personal),
                <CitizenPersonalData {...personal} />
            ),
        }),
        Contacts: getDataIfTrue(hasContactViewPermissions(userPermissions), {
            key: ROUTES['citizen-card/:uidOrCard'].contacts._relative,
            label: (
                <CollapseLabel
                    title={CITIZEN_CARD_LOCALIZATION.ADDRESS_AND_CONTACTS_TITLE}
                    svgIconType="SvgIconPlaceOutline14X20"
                />
            ),
            extra: contacts?.attention && (
                <CollapseExtra svgIconType="SvgIconAlertCircleFill16X16" />
            ),
            children: getChildrenIfTrue(
                some(contacts),
                <CitizenContactsData {...contacts} />
            ),
        }),
        Appeals: getDataIfTrue(hasAppealViewPermissions(userPermissions), {
            key: ROUTES['citizen-card/:uidOrCard'].appeals._relative,
            label: (
                <CollapseLabel
                    title={CITIZEN_CARD_LOCALIZATION.APPEALS_TITLE}
                    svgIconType="SvgIconTextboxPlusOutline19X19"
                />
            ),
            extra: isAppealsAttentionVisible && (
                <CollapseExtra svgIconType="SvgIconAlertCircleFill16X16" />
            ),
            children: getChildrenIfTrue(
                appeals?.length,
                <CitizenAppeals appeals={appeals!} />
            ),
        }),
    }

    const mergedData = mergeDataShemeAndHierarchy(
        hierarchyBaseInfoAndDocs.baseInfo,
        baseInfoCollapseScheme
    )

    return excludeKeys
        ? mergedData?.filter((dataPart) => !excludeKeys.includes(String(dataPart.key)))
        : mergedData
}

function getDocumentsList(
    {
        arrivalDocs,
        biometryFingerprintDocs,
        identityDocs,
        migrationCards,
        previousFioDocs,
        innDocs,
        dmsDocs,
        residencePermitDocs,
        temporaryResidencePermitDocs,
        foreignCards,
        testingDocs,
        medicalReportDocs,
        hivCertificateDocs,
        psychiatricReportDocs,
    }: CommonDocumentProps,
    hierarchyDocuments?: HierarchyItemContract[],
    userPermissions?: UserContract['permissions']
) {
    const documentsScheme: StoreUnionKeyPartial<
        HierarchyItemTypesContract,
        ShortDocumentInfo | undefined
    > = {
        IdentityDocument: getDataIfTrue(
            hasSingleDocViewPermission(userPermissions, 'IdentityDocumentView'),
            {
                name: CITIZEN_CARD_LOCALIZATION.IDENTITY_DOCUMENT,
                value: getSeriesAndNumber(identityDocs?.[0]),
                type: 'identity',
                attention: !!identityDocs?.[0].isExpired,
            }
        ),
        MigrationCard: getDataIfTrue(
            hasSingleDocViewPermission(userPermissions, 'MigrationCardView'),
            {
                name: CITIZEN_CARD_LOCALIZATION.MIGRATION_DOCUMENT,
                value: getSeriesAndNumber(migrationCards?.[0]),
                type: 'migration',
            }
        ),
        ArrivalNotification: getDataIfTrue(
            hasSingleDocViewPermission(userPermissions, 'ArrivalNotificationView'),
            {
                name: CITIZEN_CARD_LOCALIZATION.ARRIVAL_DOCUMENT,
                value: formatDate(arrivalDocs?.[0]?.stayExpiresAt),
                type: 'arrival',
                attention: !!arrivalDocs?.[0].isExpired,
            }
        ),
        MedicalReport: getDataIfTrue(
            hasSingleDocViewPermission(userPermissions, 'MedicalReportView'),
            {
                name: CITIZEN_CARD_LOCALIZATION.MEDICAL_VERDICT,
                value: formatDateWithExpiration(medicalReportDocs?.[0]?.expirationDate),
                type: 'medical',
                attention: !!medicalReportDocs?.[0]?.isExpired,
            }
        ),
        HivCertificate: getDataIfTrue(
            hasSingleDocViewPermission(userPermissions, 'HivCertificateView'),
            {
                name: CITIZEN_CARD_LOCALIZATION.HIV_CERTIFICATE,
                value: formatDateWithExpiration(hivCertificateDocs?.[0]?.expirationDate),
                type: 'hiv-certificate',
                attention: !!hivCertificateDocs?.[0]?.isExpired,
            }
        ),
        PsychiatricReport: getDataIfTrue(
            hasSingleDocViewPermission(userPermissions, 'PsychiatricReportView'),
            {
                name: CITIZEN_CARD_LOCALIZATION.PSYCHIATRRIST_NARCOLOGIST_VERDICT,
                value: formatDateWithExpiration(
                    psychiatricReportDocs?.[0]?.expirationDate
                ),
                type: 'psychiatrist-narcologist',
                attention: !!psychiatricReportDocs?.[0]?.isExpired,
            }
        ),
        NameChangeDocument: getDataIfTrue(
            hasSingleDocViewPermission(userPermissions, 'PreviousFioView'),
            {
                name: CITIZEN_CARD_LOCALIZATION.PREVIOUS_FIO,
                value: formatDateWithYearOfChange(previousFioDocs?.[0]?.year),
                type: 'previous-fio',
            }
        ),
        FingerprintRegistrationDocument: getDataIfTrue(
            hasSingleDocViewPermission(
                userPermissions,
                'BiometryFingerprintDocumentView'
            ),
            {
                name: CITIZEN_CARD_LOCALIZATION.BIOMETRY_FINGERPRINT,
                value: getSeriesAndNumber(biometryFingerprintDocs?.[0]),
                type: 'biometry-fingerprint',
            }
        ),
        Inn: getDataIfTrue(hasSingleDocViewPermission(userPermissions, 'InnView'), {
            name: CITIZEN_CARD_LOCALIZATION.INN_DOCUMENT,
            value: innDocs?.[0]?.innNumber,
            type: 'inn',
        }),
        DmsPolicy: getDataIfTrue(hasSingleDocViewPermission(userPermissions, 'DmsView'), {
            name: CITIZEN_CARD_LOCALIZATION.DMS_DOCUMENT,
            value: dmsDocs?.[0]?.number,
            type: 'dms',
            attention: !!dmsDocs?.[0]?.isExpired,
        }),
        ResidencyPermit: getDataIfTrue(
            hasSingleDocViewPermission(userPermissions, 'ResidencePermitView'),
            {
                name: CITIZEN_CARD_LOCALIZATION.RESIDENCE_PERMIT_DOCUMENT,
                value: formatDateWithExpiration(residencePermitDocs?.[0]?.expirationDate),
                type: 'residence-permit',
                attention: !!residencePermitDocs?.[0]?.isExpired,
            }
        ),
        TemporaryResidencePermit: getDataIfTrue(
            hasSingleDocViewPermission(userPermissions, 'TemporaryResidencePermitView'),
            {
                name: CITIZEN_CARD_LOCALIZATION.TEMPORARY_RESIDENCE_PERMIT_DOCUMENT,
                value: formatDateWithExpiration(
                    temporaryResidencePermitDocs?.[0]?.expirationDate
                ),
                type: 'temporary-residence-permit',
                attention: !!temporaryResidencePermitDocs?.[0]?.isExpired,
            }
        ),
        ElectronicCitizenCard: getDataIfTrue(
            hasSingleDocViewPermission(userPermissions, 'ForeignCardView'),
            {
                name: CITIZEN_CARD_LOCALIZATION.FOREIGN_CARD_DOCUMENT,
                value: formatDateWithExpiration(foreignCards?.[0]?.expirationDate),
                type: 'foreign-cards',
                attention: !!foreignCards?.[0]?.isExpired,
            }
        ),
        Testing: getDataIfTrue(
            hasSingleDocViewPermission(userPermissions, 'TestingView'),
            {
                name: CITIZEN_CARD_LOCALIZATION.TESTING_DOCUMENT,
                value: getSeriesAndNumber(testingDocs?.[0]),
                type: 'testing',
            }
        ),
    }

    return mergeDataShemeAndHierarchy(hierarchyDocuments, documentsScheme)
}
