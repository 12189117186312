import React, { FC } from 'react'
import { ComponentWithChildrenProps } from 'mmc-react-shared'
import { Header } from 'components/layouts/Header'
import { Layout } from '../Layout'

export const LayoutOneColumn: FC<ComponentWithChildrenProps> = React.memo(
    ({ children }) => {
        return (
            <Layout
                className="bg-color-neutral-4"
                mainClassName="w-full justify-center py-12 px-4 relative  flex-auto"
                headerContent={<Header />}
                headerClassName="flex bg-white justify-between px-4 py-2"
            >
                {children}
            </Layout>
        )
    }
)
