import React from 'react'

import { Route, Routes } from 'react-router-dom'
import { ROUTES } from './routes'
import { NotFound } from 'pages/NotFound'
import { SearchByQR } from 'pages/SearchByQR'
import { SearchByCard } from 'pages/SearchByCard'
import { NoResultsFound } from 'pages/NoResultsFound'
import { CitizenCard } from 'pages/CitizenCard'
import { CitizenCardClose } from 'pages/CitizenCardClose'
import { Home } from 'pages/Home'
import { Logout } from 'pages/Logout'
import { CitizenCardDocument } from 'pages/CitizenCardDocument'
import { CitizenCardAppeal } from 'pages/CitizenCardAppeal'
import { CitizenCardPatent } from 'pages/CitizenCardPatent'

export const Routing = React.memo(() => {
    return (
        <Routes>
            <Route path={ROUTES._basePath} element={<Home />} />

            <Route path={ROUTES['search-by-qr']._absolute} element={<SearchByQR />} />

            <Route path={ROUTES['search-by-card']._absolute} element={<SearchByCard />} />

            <Route
                path={ROUTES['citizen-card/:uidOrCard'].exit._absolute}
                element={<CitizenCardClose />}
            />

            <Route
                path={
                    ROUTES['citizen-card/:uidOrCard'].appeals[':appealNumber']._absolute
                }
                element={<CitizenCardAppeal />}
            />

            <Route
                path={
                    ROUTES['citizen-card/:uidOrCard'].documents[':documentType']._absolute
                }
                element={<CitizenCardDocument />}
            />

            <Route
                path={
                    ROUTES['citizen-card/:uidOrCard'].patents[':patentSeriesNumber']
                        ._absolute
                }
                element={<CitizenCardPatent />}
            />

            {[
                ROUTES['citizen-card/:uidOrCard'].appeals._absolute,
                ROUTES['citizen-card/:uidOrCard'].documents._absolute,
                ROUTES['citizen-card/:uidOrCard'].patents._absolute,
                ROUTES['citizen-card/:uidOrCard'].personal._absolute,
                ROUTES['citizen-card/:uidOrCard'].contacts._absolute,
                ROUTES['citizen-card/:uidOrCard']._absolute,
            ].map((path) => (
                <Route key={path} path={path} element={<CitizenCard />} />
            ))}

            <Route path={ROUTES['no-results']._absolute} element={<NoResultsFound />} />

            <Route path={ROUTES.logout._absolute} element={<Logout />} />

            <Route path="*" element={<NotFound />} />
        </Routes>
    )
})
