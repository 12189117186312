import React, { FC } from 'react'
import { CitizenCardHeader } from 'components/business/CitizenCardHeader'
import { TESTING_DOCUMENT_LOCALIZATION } from './TestingDocument.localization'
import { Col, Row } from 'antd'
import { FieldView } from 'components/controls/FieldView'
import { useGetProfile } from 'hooks/useGetProfile'
import { formatDate } from 'mmc-react-shared'
import { getSeriesAndNumber } from 'utils/common.utils'
import {
    CITIZEN_DOC_GRID_COL,
    CITIZEN_DOC_GRID_ROW,
} from 'pages/CitizenCardDocument/CitizenCardDocument.consts'
import { DocumentScanView } from 'components/controls/DocumentScanView'
import { useGetApiUserQuery } from 'api/generator/appApi'
import { hasDocScanViewPermission } from 'utils/permissions.utils'

export const TestingDocument: FC = React.memo(() => {
    const { profile } = useGetProfile()

    const { data: user } = useGetApiUserQuery()

    const document = profile?.testingDocs?.[0] || {}

    return (
        <>
            <CitizenCardHeader title={TESTING_DOCUMENT_LOCALIZATION.TITLE} />

            <Row {...CITIZEN_DOC_GRID_ROW}>
                <Col {...CITIZEN_DOC_GRID_COL}>
                    <FieldView
                        label={TESTING_DOCUMENT_LOCALIZATION.SERIES_AND_NUMBER}
                        value={getSeriesAndNumber(document)}
                    />
                </Col>

                <Col {...CITIZEN_DOC_GRID_COL}>
                    <FieldView
                        label={TESTING_DOCUMENT_LOCALIZATION.ISSUED_AT}
                        value={formatDate(document.issuedAt)}
                    />
                </Col>

                {hasDocScanViewPermission(user?.permissions, 'TestingScanView') && (
                    <Col xs={24}>
                        <DocumentScanView files={document.files} />
                    </Col>
                )}
            </Row>
        </>
    )
})
