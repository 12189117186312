import { memo, useCallback } from 'react'

import classNames from 'classnames'
import { Button, Preloader, Tag } from 'mmc-react-shared'
import { generatePath, useParams } from 'react-router-dom'

import { ROUTES } from 'routing/routes'
import { useNavigator } from 'hooks/useNavigator'
import { useGetProfile } from 'hooks/useGetProfile'
import { getSeriesAndNumber } from 'utils/common.utils'

import { Layout } from 'components/layouts/Layout'
import { TopControlPanel } from 'components/layouts/TopControlPanel'
import { PersonalInformation } from 'components/business/PersonalInformation'
import { CitizenCardHeader } from 'components/business/CitizenCardHeader'

import { PatentInformation } from './components/PatentInformation'
import { PaymentInformation } from './components/PaymentInformation'
import { PatentPaymentScale } from './components/PatentPaymentScale'

import { CITIZEN_CARD_PATENTS_LOCALIZATION } from './CitizenCardPatent.localization'
import styles from './CitizenCardPatent.module.css'

export const CitizenCardPatent = memo(() => {
    const { profile, isLoading, isError, profilePhotoSrc, uidOrCard } = useGetProfile()

    const { patentSeriesNumber } = useParams()

    const { goToCitizenCardWithScroll } = useNavigator()

    const handleReturn = useCallback(() => {
        const path = generatePath(ROUTES['citizen-card/:uidOrCard'].patents._absolute, {
            uidOrCard,
        })

        goToCitizenCardWithScroll(path, patentSeriesNumber)
    }, [uidOrCard, patentSeriesNumber, goToCitizenCardWithScroll])

    const patent = profile?.patentDocs?.find(
        (patentDoc) => getSeriesAndNumber(patentDoc, '-') === patentSeriesNumber
    )

    const scalePaymentsInfo = patent?.paymentInfo?.validityMonths

    const patentPayments = profile?.payments?.filter(
        ({ patentSeries: series, patentNumber: number }) =>
            getSeriesAndNumber(
                {
                    series,
                    number,
                },
                '-'
            ) === patentSeriesNumber
    )

    const isPatentPaid = patent?.paymentInfo?.isPaid

    return (
        <Preloader isLoading={isLoading} isError={isError} positionCenter>
            <Layout
                mainClassName="h-full flex-col bg-color-neutral-1 p-6"
                headerClassName="relative"
                headerContent={
                    <>
                        <TopControlPanel
                            title={CITIZEN_CARD_PATENTS_LOCALIZATION.TITLE}
                            onReturn={handleReturn}
                        />

                        {!!profile && (
                            <PersonalInformation
                                name={profile.name}
                                surname={profile.surname}
                                patronymic={profile.patronymic}
                                citizenship={profile.citizenship}
                                birthdate={profile.birthdate}
                                gender={profile.gender}
                                photoSrc={profilePhotoSrc}
                                attention={profile.isWanted}
                                patentDocs={profile.patentDocs}
                                identityDocs={profile.identityDocs}
                                biometryFingerprintDocs={profile.biometryFingerprintDocs}
                                testingDocs={profile.testingDocs}
                            />
                        )}
                    </>
                }
            >
                <CitizenCardHeader
                    svgType="SvgIconPlaceOutline14X20"
                    title={patent?.region}
                />

                <div className="tablet:px-16">
                    <Tag
                        text={
                            isPatentPaid
                                ? CITIZEN_CARD_PATENTS_LOCALIZATION.STATUS_PAID
                                : CITIZEN_CARD_PATENTS_LOCALIZATION.STATUS_UNPAID
                        }
                        type="input"
                        preset={isPatentPaid ? 'success' : 'error'}
                        uppercase
                        className="mr-auto mb-6"
                    />

                    <PatentInformation patent={patent} />

                    <div className={classNames(styles.tabsContainer, 'py-3')}>
                        {/* TODO: MMCSERV-12038 - Пока нет никаких других вкладок -
                         *  рендерим кнопку. После внедрения
                         *  других вкладок - создать отдельный
                         *  компонент  */}
                        <Button
                            className={classNames(styles.tabPayment, 'cursor-auto')}
                            type="dark-outline"
                        >
                            {`${CITIZEN_CARD_PATENTS_LOCALIZATION.PAYMENTS} (${
                                patentPayments?.length || 0
                            })`}
                        </Button>
                    </div>

                    <PatentPaymentScale payments={scalePaymentsInfo} />

                    <PaymentInformation payments={patentPayments} />
                </div>
            </Layout>
        </Preloader>
    )
})
