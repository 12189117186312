import { Button, LOCALIZATION } from 'mmc-react-shared'
import { FC } from 'react'
import { LOGOUT_LOCALIZATION } from './Logout.localization'
import { useNavigator } from 'hooks/useNavigator'
import { authLogout } from 'utils/common.utils'
import { Layout } from 'components/layouts/Layout'
import { Typography } from 'mmc-react-theme'

export const Logout: FC = () => {
    const { goBack } = useNavigator()

    return (
        <Layout mainClassName="h-full justify-center bg-white row-span-full">
            <div className="flex flex-col items-center justify-center gap-y-12 px-12">
                <Typography
                    type="heading-4"
                    text={LOGOUT_LOCALIZATION.TITLE}
                    textColor="color-neutral-19"
                />

                <Typography
                    text={LOGOUT_LOCALIZATION.CONTENT}
                    textColor="color-neutral-19"
                    className="text-center"
                />

                <div className="flex flex-col w-full tablet:flex-row tablet:w-auto">
                    <Button
                        type="dark"
                        className="w-full mb-3 tablet:mr-6 tablet:w-auto tablet:mb-0"
                        onClick={authLogout}
                    >
                        {LOCALIZATION.ACTIONS.EXIT}
                    </Button>

                    <Button
                        type="secondary"
                        className="w-full tablet:w-auto"
                        onClick={goBack}
                    >
                        {LOCALIZATION.ACTIONS.CANCEL}
                    </Button>
                </div>
            </div>
        </Layout>
    )
}
