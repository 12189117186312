export const TEST_IDS = {
    OFFLINE__REFRESH: 'OFFLINE__REFRESH',
    HEADER__LOGO: 'HEADER__LOGO',
    HEADER__LOGOUT: 'HEADER__LOGOUT',
    CITIZEN_FIO: 'CITIZEN_FIO',
    FIELD_VIEW: 'FIELD_VIEW',
    COLLAPSE_ITEM: 'COLLAPSE_ITEM',
    ATTENTION: 'ATTENTION',
    INFO_FIELDS: 'INFO_FIELDS',
    PERSONAL_INFORMATION_VIEW_TABLET: 'PERSONAL_INFORMATION_VIEW_TABLET',
    PERSONAL_INFORMATION_VIEW_MOBILE: 'PERSONAL_INFORMATION_VIEW_MOBILE',
    PERSONAL_INFORMATION_PHOTO: 'PERSONAL_INFORMATION_PHOTO',
}
