import { APP_LOCALIZATION } from 'localization'
import { Empty } from 'mmc-react-shared'

export const DocPermissionDenied = () => {
    return (
        <Empty
            description={APP_LOCALIZATION.PLACEHOLDERS.PERMISSION_DENIED}
            heightFull
            icon={null}
        />
    )
}
