import React, { FC } from 'react'
import { TAILWIND_THEME_COLORS, Typography } from 'mmc-react-theme'
import { Icon } from 'mmc-react-icons'
import { CollapseLabelProps } from './CollapseLabel.types'

export const CollapseLabel: FC<CollapseLabelProps> = React.memo(
    ({ title, svgIconType }) => {
        return (
            <Typography type="heading-6" weight="600" className="flex items-center">
                <span className="flex items-center justify-center size-10 rounded-s bg-color-neutral-2 mr-2 tablet:mr-3">
                    <Icon
                        svgType={svgIconType}
                        pathFill={TAILWIND_THEME_COLORS['color-neutral-15']}
                    />
                </span>

                {title}
            </Typography>
        )
    }
)
