import { getDynamicTestId } from 'mmc-react-shared'
import { CollapseItemProps } from './CollapseItem.types'
import { TEST_IDS } from 'consts/tests.consts'

export const getCollapseItemDataAttr = ({
    dataScrollAttrKey,
    dataScrollAttrValue,
}: Pick<CollapseItemProps, 'dataScrollAttrKey' | 'dataScrollAttrValue'>) => {
    const dataAttr = {
        [`data-${dataScrollAttrKey}`]: dataScrollAttrValue,
    }

    const dataTestId = getDynamicTestId(TEST_IDS.COLLAPSE_ITEM, dataScrollAttrValue)

    return { dataAttr, dataTestId }
}
