import { FC, memo } from 'react'
import classNames from 'classnames'
import styles from './PatentPaymentScale.module.css'
import { Row } from 'antd'
import { PATENT_PAYMENT_SCALE_LOCALIZATION } from './PatentPaymentScale.localization'
import { PatentPaymentScaleProps } from './PatentPaymentScale.types'
import { LegendLabel } from '../LegendLabel'
import { ScaleItem } from '../ScaleItem'

export const PatentPaymentScale: FC<PatentPaymentScaleProps> = memo(({ payments }) => {
    return (
        <div className={classNames(styles.root, 'py-6 tablet:py-12')}>
            <Row gutter={[0, 12]}>
                {payments?.map((payment) => (
                    <ScaleItem payment={payment} key={payment.monthNumber} />
                ))}
            </Row>

            <div className={classNames(styles.legend, 'pt-6')}>
                <LegendLabel type="paid" text={PATENT_PAYMENT_SCALE_LOCALIZATION.PAID} />

                <LegendLabel
                    type="unpaid"
                    text={PATENT_PAYMENT_SCALE_LOCALIZATION.UNPAID}
                />

                <LegendLabel
                    type="expired"
                    text={PATENT_PAYMENT_SCALE_LOCALIZATION.EXPIRED}
                />
            </div>
        </div>
    )
})
