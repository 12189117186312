import React, { FC, useCallback } from 'react'
import { generatePath, useParams } from 'react-router-dom'
import { TopControlPanel } from 'components/layouts/TopControlPanel'
import { PersonalInformation } from 'components/business/PersonalInformation'
import { ROUTES } from 'routing/routes'
import { Preloader } from 'mmc-react-shared'
import { useGetProfile } from 'hooks/useGetProfile'
import { CITIZEN_CARD_LOCALIZATION } from 'pages/CitizenCard/CitizenCard.localization'
import { useNavigator } from 'hooks/useNavigator'
import {
    DOCUMENT_DICTIONARY,
    DOCUMENT_PERMISSON_DICTIONARY,
} from './CitizenCardDocument.config'
import { DocumentType } from 'types/business.types'
import { Layout } from 'components/layouts/Layout'
import { hasSingleDocViewPermission } from 'utils/permissions.utils'
import { useGetApiUserQuery } from 'api/generator/appApi'
import { DocPermissionDenied } from './components/DocPermissionDenied'

export const CitizenCardDocument: FC = React.memo(() => {
    const { profile, isLoading, isError, uidOrCard, profilePhotoSrc } = useGetProfile()

    const { data: user } = useGetApiUserQuery()

    const { goToCitizenCardWithScroll } = useNavigator()

    const { documentType } = useParams<{ documentType: DocumentType }>()

    const handleReturn = useCallback(() => {
        const path = generatePath(ROUTES['citizen-card/:uidOrCard'].documents._absolute, {
            uidOrCard,
        })

        goToCitizenCardWithScroll(path, documentType)
    }, [uidOrCard, documentType, goToCitizenCardWithScroll])

    const DocumentView = documentType && DOCUMENT_DICTIONARY[documentType]

    const documentPermission = documentType && DOCUMENT_PERMISSON_DICTIONARY[documentType]

    const hasDocViewPermission = hasSingleDocViewPermission(
        user?.permissions,
        documentPermission
    )

    return (
        <Preloader isLoading={isLoading} isError={isError} positionCenter>
            <Layout
                mainClassName="h-full flex-col bg-color-neutral-1 p-6"
                headerClassName="relative"
                headerContent={
                    <div>
                        <TopControlPanel
                            title={CITIZEN_CARD_LOCALIZATION.DOCUMENT_TITLE}
                            onReturn={handleReturn}
                        />

                        {!!profile && (
                            <PersonalInformation
                                name={profile.name}
                                surname={profile.surname}
                                patronymic={profile.patronymic}
                                citizenship={profile.citizenship}
                                birthdate={profile.birthdate}
                                gender={profile.gender}
                                photoSrc={profilePhotoSrc}
                                attention={profile.isWanted}
                                patentDocs={profile.patentDocs}
                                identityDocs={profile.identityDocs}
                                biometryFingerprintDocs={profile.biometryFingerprintDocs}
                                testingDocs={profile.testingDocs}
                            />
                        )}
                    </div>
                }
            >
                {hasDocViewPermission && DocumentView ? (
                    <DocumentView />
                ) : (
                    <DocPermissionDenied />
                )}
            </Layout>
        </Preloader>
    )
})
