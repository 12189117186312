import { memo } from 'react'

import { ComponentWithChildrenProps } from 'mmc-react-shared'
import { Typography } from 'mmc-react-theme'

export const CitizenDataPlaceholder: React.FC<ComponentWithChildrenProps> = memo(
    ({ children }) => (
        <Typography
            className="pt-5 pb-8 tablet:px-3 tablet:pb-0"
            textColor="color-neutral-11"
        >
            <div className="ps-10 ms-2 tablet:ms-3">{children}</div>
        </Typography>
    )
)
