import { memo } from 'react'

import { TAILWIND_THEME_COLORS, Typography } from 'mmc-react-theme'
import { Icon } from 'mmc-react-icons'
import { CitizenCardHeaderProps } from './CitizenCardHeader.types'

export const CitizenCardHeader: React.FC<CitizenCardHeaderProps> = memo(
    ({ svgType, title }) => {
        return (
            <Typography
                type="heading-6"
                weight="600"
                className="flex items-center mb-6 tablet:p-3 tablet:mb-3"
            >
                <Icon
                    svgType={svgType || 'SvgIconFileDocumentOutline16X20'}
                    pathFill={TAILWIND_THEME_COLORS['color-neutral-15']}
                    iconContainerPreset="40"
                    iconContainerBg="bg-color-neutral-2"
                    iconContainerClassName="mr-3 hidden tablet:flex rounded-s"
                />

                {title}
            </Typography>
        )
    }
)
