import React, { FC } from 'react'
import { AddressFieldsProps } from './AddressFields.types'
import { Col } from 'antd'
import { CITIZEN_CONTACTS_DATA_LOCALIZATION } from './AddressFields.localization'
import { FieldView } from 'components/controls/FieldView'
import { Typography } from 'mmc-react-theme'

export const AddressFields: FC<AddressFieldsProps> = React.memo(({ title, address }) => {
    return (
        <>
            <Col span={24}>
                <Typography weight="600">{title}</Typography>
            </Col>

            <Col span={24} md={6}>
                <FieldView
                    label={CITIZEN_CONTACTS_DATA_LOCALIZATION.REGION}
                    value={address?.region}
                />
            </Col>

            <Col span={24} md={6}>
                <FieldView
                    label={CITIZEN_CONTACTS_DATA_LOCALIZATION.CITY}
                    value={address?.city}
                />
            </Col>

            <Col span={24} md={6}>
                <FieldView
                    label={CITIZEN_CONTACTS_DATA_LOCALIZATION.DISTRICT}
                    value={address?.district}
                />
            </Col>

            <Col span={24} md={6}>
                <FieldView
                    label={CITIZEN_CONTACTS_DATA_LOCALIZATION.STREET}
                    value={address?.street}
                />
            </Col>

            <Col span={12} md={6}>
                <FieldView
                    label={CITIZEN_CONTACTS_DATA_LOCALIZATION.BUILDING}
                    value={address?.building}
                />
            </Col>

            <Col span={12} md={6}>
                <FieldView
                    label={CITIZEN_CONTACTS_DATA_LOCALIZATION.FLAT}
                    value={address?.flat}
                />
            </Col>
        </>
    )
})
