import React, { FC } from 'react'
import { ARRIVAL_DOCUMENT_LOCALIZATION } from './ArrivalDocument.localization'
import { Col, Row } from 'antd'
import { FieldView } from 'components/controls/FieldView'
import { useGetProfile } from 'hooks/useGetProfile'
import { formatDate } from 'mmc-react-shared'
import {
    CITIZEN_DOC_GRID_COL,
    CITIZEN_DOC_GRID_ROW,
} from 'pages/CitizenCardDocument/CitizenCardDocument.consts'
import { DocumentScanView } from 'components/controls/DocumentScanView'
import { hasDocScanViewPermission } from 'utils/permissions.utils'
import { useGetApiUserQuery } from 'api/generator/appApi'
import { CitizenCardHeader } from 'components/business/CitizenCardHeader'
import { getDateColorByExpiration } from 'pages/CitizenCardDocument/CitizenCardDocument.utils'

export const ArrivalDocument: FC = React.memo(() => {
    const { profile } = useGetProfile()

    const { data: user } = useGetApiUserQuery()

    const document = profile?.arrivalDocs?.[0] || {}

    return (
        <>
            <CitizenCardHeader title={ARRIVAL_DOCUMENT_LOCALIZATION.TITLE} />

            <Row {...CITIZEN_DOC_GRID_ROW}>
                <Col {...CITIZEN_DOC_GRID_COL}>
                    <FieldView
                        label={ARRIVAL_DOCUMENT_LOCALIZATION.ISSUER}
                        value={document.issuer}
                    />
                </Col>

                <Col {...CITIZEN_DOC_GRID_COL}>
                    <FieldView
                        label={ARRIVAL_DOCUMENT_LOCALIZATION.STAY_EXPIRES_AT}
                        value={formatDate(document.stayExpiresAt)}
                        valueColor={getDateColorByExpiration(document.isExpired)}
                    />
                </Col>

                <Col {...CITIZEN_DOC_GRID_COL}>
                    <FieldView
                        label={ARRIVAL_DOCUMENT_LOCALIZATION.REGISTRATION_DATE}
                        value={formatDate(document.registrationDate)}
                    />
                </Col>

                {hasDocScanViewPermission(
                    user?.permissions,
                    'ArrivalNotificationScanView'
                ) && (
                    <Col xs={24}>
                        <DocumentScanView files={document.files} />
                    </Col>
                )}
            </Row>
        </>
    )
})
