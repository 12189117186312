import { FC, memo } from 'react'
import { Typography } from 'mmc-react-theme'
import { Icon } from 'mmc-react-icons'
import { AttentionProps } from './Attention.types'
import classNames from 'classnames'
import { TEST_IDS } from 'consts/tests.consts'

export const Attention: FC<AttentionProps> = memo(
    ({ text, className, dataTestId = TEST_IDS.ATTENTION, componentRef, style }) => {
        return (
            <Typography
                weight="500"
                textColor="color-red-9"
                className={classNames(className, 'flex items-center')}
                dataTestId={dataTestId}
                componentRef={componentRef}
                style={style}
            >
                <Icon
                    svgType="SvgIconAlertCircleFill16X16"
                    className="mr-2"
                    pathFillCurrent
                />

                {text}
            </Typography>
        )
    }
)
