import React, { FC } from 'react'
import { CitizenCardHeader } from 'components/business/CitizenCardHeader'
import { TEMPORARY_RESIDENCE_PERMIT_DOCUMENT_LOCALIZATION } from './TemporaryResidencePermit.localization'
import { Col, Row } from 'antd'
import { FieldView } from 'components/controls/FieldView'
import { useGetProfile } from 'hooks/useGetProfile'
import { formatDate } from 'mmc-react-shared'
import { getSeriesAndNumber } from 'utils/common.utils'
import {
    CITIZEN_DOC_GRID_COL,
    CITIZEN_DOC_GRID_ROW,
} from 'pages/CitizenCardDocument/CitizenCardDocument.consts'
import { DocumentScanView } from 'components/controls/DocumentScanView'
import { useGetApiUserQuery } from 'api/generator/appApi'
import { hasDocScanViewPermission } from 'utils/permissions.utils'
import { getDateColorByExpiration } from 'pages/CitizenCardDocument/CitizenCardDocument.utils'

export const TemporaryResidencePermit: FC = React.memo(() => {
    const { profile } = useGetProfile()

    const { data: user } = useGetApiUserQuery()

    const document = profile?.temporaryResidencePermitDocs?.[0] || {}

    return (
        <>
            <CitizenCardHeader
                title={TEMPORARY_RESIDENCE_PERMIT_DOCUMENT_LOCALIZATION.TITLE}
            />

            <Row {...CITIZEN_DOC_GRID_ROW}>
                <Col {...CITIZEN_DOC_GRID_COL}>
                    <FieldView
                        label={
                            TEMPORARY_RESIDENCE_PERMIT_DOCUMENT_LOCALIZATION.SERIES_AND_NUMBER
                        }
                        value={getSeriesAndNumber(document)}
                    />
                </Col>

                <Col {...CITIZEN_DOC_GRID_COL}>
                    <FieldView
                        label={
                            TEMPORARY_RESIDENCE_PERMIT_DOCUMENT_LOCALIZATION.BLANK_SERIES_AND_NUMBER
                        }
                        value={getSeriesAndNumber({
                            series: document.blankSeries,
                            number: document.blankNumber,
                        })}
                    />
                </Col>

                <Col {...CITIZEN_DOC_GRID_COL}>
                    <FieldView
                        label={TEMPORARY_RESIDENCE_PERMIT_DOCUMENT_LOCALIZATION.ISSUED_AT}
                        value={formatDate(document.issuedAt)}
                    />
                </Col>

                <Col {...CITIZEN_DOC_GRID_COL}>
                    <FieldView
                        label={
                            TEMPORARY_RESIDENCE_PERMIT_DOCUMENT_LOCALIZATION.EXPIRATION_DATE
                        }
                        value={formatDate(document.expirationDate)}
                        valueColor={getDateColorByExpiration(document.isExpired)}
                    />
                </Col>

                <Col {...CITIZEN_DOC_GRID_COL}>
                    <FieldView
                        label={TEMPORARY_RESIDENCE_PERMIT_DOCUMENT_LOCALIZATION.ISSUER}
                        value={document.issuer}
                    />
                </Col>

                {hasDocScanViewPermission(
                    user?.permissions,
                    'TemporaryResidencePermitScanView'
                ) && (
                    <Col xs={24}>
                        <DocumentScanView files={document.files} />
                    </Col>
                )}
            </Row>
        </>
    )
})
