import React, { FC } from 'react'
import cn from 'classnames'
import { CollapseItemProps } from './CollapseItem.types'
import styles from './CollapseItem.module.css'
import { Icon } from 'mmc-react-icons'
import { getCollapseItemDataAttr } from './CollapseItem.utils'
import { TAILWIND_THEME_COLORS } from 'mmc-react-theme'

export const CollapseItem: FC<CollapseItemProps> = React.memo(
    ({
        children,
        onClick,
        dataScrollAttrKey,
        dataScrollAttrValue,
        isActive,
        isAttention,
    }) => {
        const { dataAttr, dataTestId } = getCollapseItemDataAttr({
            dataScrollAttrKey,
            dataScrollAttrValue,
        })

        return (
            <div
                className={cn(
                    styles.root,
                    'py-3 mx-0 last:mb-3',
                    'tablet:py-6 tablet:mx-16 last:tablet:mb-6',
                    isActive && 'cursor-pointer'
                )}
                onClick={isActive ? onClick : undefined}
                data-testid={dataTestId}
                {...dataAttr}
            >
                {children}

                <div className="flex">
                    {isAttention && (
                        <Icon
                            svgType="SvgIconAlertCircleFill16X16"
                            pathFill={TAILWIND_THEME_COLORS['color-red-9']}
                        />
                    )}

                    {isActive && (
                        <Icon
                            pathFill={TAILWIND_THEME_COLORS['color-neutral-19']}
                            svgType="SvgIconChevronRightRight7X12"
                            iconContainerPreset="24"
                        />
                    )}
                </div>
            </div>
        )
    }
)
