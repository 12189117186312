import React, { FC, useCallback } from 'react'
import { Col, Row } from 'antd'
import { PersonalInformation } from 'components/business/PersonalInformation'
import { FieldView } from 'components/controls/FieldView'
import { TopControlPanel } from 'components/layouts/TopControlPanel'
import { useGetProfile } from 'hooks/useGetProfile'
import { Preloader } from 'mmc-react-shared'
import { generatePath, useParams } from 'react-router-dom'
import { ROUTES } from 'routing/routes'
import { CitizenCardUrlParams } from 'types/business.types'
import { CITIZEN_CARD_APPEAL_LOCALIZATION } from './CitizenCardAppeal.localization'
import { TAG_PRESETS } from './CitizenCardAppeal.const'
import { formatDateWithDaysAgo } from './CitizenCardAppeal.utils'
import { useNavigator } from 'hooks/useNavigator'
import { Tag } from 'components/common/Tag'
import { Layout } from 'components/layouts/Layout'
import { CitizenCardHeader } from 'components/business/CitizenCardHeader'

export const CitizenCardAppeal: FC = React.memo(() => {
    const { profile, isLoading, isError, uidOrCard, profilePhotoSrc } = useGetProfile()

    const { appealNumber } = useParams<CitizenCardUrlParams>()

    const appeal = profile?.appeals?.find(({ number }) => appealNumber === number)

    const presetTag = appeal?.statusCode ? TAG_PRESETS[appeal.statusCode] : undefined

    const { goToCitizenCardWithScroll } = useNavigator()

    const handleReturn = useCallback(() => {
        const path = generatePath(ROUTES['citizen-card/:uidOrCard'].appeals._absolute, {
            uidOrCard,
        })

        goToCitizenCardWithScroll(path, appeal?.number)
    }, [appeal?.number, uidOrCard, goToCitizenCardWithScroll])

    return (
        <Preloader isLoading={isLoading} isError={isError} positionCenter>
            <Layout
                mainClassName="h-full flex-col bg-color-neutral-1 p-6"
                headerClassName="relative"
                headerContent={
                    <div>
                        <TopControlPanel
                            title={CITIZEN_CARD_APPEAL_LOCALIZATION.TITLE}
                            onReturn={handleReturn}
                        />

                        {!!profile && (
                            <PersonalInformation
                                name={profile.name}
                                surname={profile.surname}
                                patronymic={profile.patronymic}
                                citizenship={profile.citizenship}
                                birthdate={profile.birthdate}
                                gender={profile.gender}
                                photoSrc={profilePhotoSrc}
                                attention={profile.isWanted}
                                patentDocs={profile.patentDocs}
                                identityDocs={profile.identityDocs}
                                biometryFingerprintDocs={profile.biometryFingerprintDocs}
                                testingDocs={profile.testingDocs}
                            />
                        )}
                    </div>
                }
            >
                <CitizenCardHeader title={appeal?.mainService} />

                <div className="tablet:px-16">
                    <Tag
                        text={appeal?.status || undefined}
                        type="input"
                        preset={presetTag}
                        uppercase
                        withoutRestrictions
                        className="mb-6"
                    />

                    <Row gutter={[24, 12]}>
                        <Col xs={24} md={12}>
                            <FieldView
                                label={CITIZEN_CARD_APPEAL_LOCALIZATION.ACCEPTANCE_DATE}
                                value={formatDateWithDaysAgo(appeal?.acceptanceDate)}
                            />
                        </Col>

                        <Col xs={24} md={12}>
                            <FieldView
                                label={CITIZEN_CARD_APPEAL_LOCALIZATION.LAST_UPDATE_DATE}
                                value={formatDateWithDaysAgo(appeal?.statusDate)}
                            />
                        </Col>
                    </Row>
                </div>
            </Layout>
        </Preloader>
    )
})
