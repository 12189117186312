import { FC, memo } from 'react'

import { getShortName, useBreakpoint } from 'mmc-react-shared'
import { HEADER_LOCALIZATION } from './Header.localization'
import Logo from './logo.svg?react'

import { useNavigator } from 'hooks/useNavigator'
import { useGetApiUserQuery } from 'api/generator/appApi'
import { Tooltip } from 'antd'
import { TEST_IDS } from 'consts/tests.consts'
import { TAILWIND_THEME_COLORS, Typography } from 'mmc-react-theme'
import { Icon } from 'mmc-react-icons'

export const Header: FC = memo(() => {
    const { goToHome, goToLogout } = useNavigator()

    const isTablet = useBreakpoint('Tablet')

    const { data: user } = useGetApiUserQuery()

    const fio = getShortName(user)

    return (
        <>
            <div
                className="flex items-center cursor-pointer"
                onClick={goToHome}
                data-testid={TEST_IDS.HEADER__LOGO}
            >
                <Logo />

                <Typography type="heading-6" weight="700" className="ms-4">
                    {HEADER_LOCALIZATION.TITLE}
                </Typography>
            </div>

            <div className="flex items-center gap-x-1">
                {isTablet && (
                    <Typography inline weight="500" textColor="color-neutral-13">
                        {fio}
                    </Typography>
                )}

                <Tooltip title={isTablet ? '' : fio} arrow={false} trigger="click">
                    <Icon
                        svgType="SvgIconAccountCircleFill20X20"
                        pathFill={TAILWIND_THEME_COLORS['color-neutral-11']}
                        iconContainerPreset="40"
                        size={24}
                    />
                </Tooltip>
                <Icon
                    svgType="SvgIconLogout18X18"
                    pathFill={TAILWIND_THEME_COLORS['color-neutral-11']}
                    iconContainerPreset="40"
                    onClick={goToLogout}
                    dataTestId={TEST_IDS.HEADER__LOGOUT}
                />
            </div>
        </>
    )
})
