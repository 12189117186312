import { emptySplitApi as api } from './emptyApi'
export const addTagTypes = [
    'Auth',
    'File',
    'ForeignProfile',
    'HierarchyItem',
    'User',
] as const
const injectedRtkApi = api
    .enhanceEndpoints({
        addTagTypes,
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getAuthLogin: build.query<GetAuthLoginApiResponse, GetAuthLoginApiArg>({
                query: (queryArg) => ({
                    url: `/auth/login`,
                    params: { returnUrl: queryArg.returnUrl },
                }),
                providesTags: ['Auth'],
            }),
            getApiLogin: build.query<GetApiLoginApiResponse, GetApiLoginApiArg>({
                query: (queryArg) => ({
                    url: `/api/login`,
                    params: { returnUrl: queryArg.returnUrl },
                }),
                providesTags: ['Auth'],
            }),
            getAuthLogout: build.query<GetAuthLogoutApiResponse, GetAuthLogoutApiArg>({
                query: (queryArg) => ({
                    url: `/auth/logout`,
                    params: { returnUrl: queryArg.returnUrl },
                }),
                providesTags: ['Auth'],
            }),
            getApiLogout: build.query<GetApiLogoutApiResponse, GetApiLogoutApiArg>({
                query: (queryArg) => ({
                    url: `/api/logout`,
                    params: { returnUrl: queryArg.returnUrl },
                }),
                providesTags: ['Auth'],
            }),
            postAuthSigninCasSsoCallback: build.mutation<
                PostAuthSigninCasSsoCallbackApiResponse,
                PostAuthSigninCasSsoCallbackApiArg
            >({
                query: () => ({ url: `/auth/signin-cas/sso-callback`, method: 'POST' }),
                invalidatesTags: ['Auth'],
            }),
            postApiSigninCasSsoCallback: build.mutation<
                PostApiSigninCasSsoCallbackApiResponse,
                PostApiSigninCasSsoCallbackApiArg
            >({
                query: () => ({ url: `/api/signin-cas/sso-callback`, method: 'POST' }),
                invalidatesTags: ['Auth'],
            }),
            getApiFileByFileId: build.query<
                GetApiFileByFileIdApiResponse,
                GetApiFileByFileIdApiArg
            >({
                query: (queryArg) => ({ url: `/api/File/${queryArg.fileId}` }),
                providesTags: ['File'],
            }),
            getApiProfileByQrCode: build.query<
                GetApiProfileByQrCodeApiResponse,
                GetApiProfileByQrCodeApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/profile/by-qr-code`,
                    params: { qrCodeData: queryArg.qrCodeData },
                }),
                providesTags: ['ForeignProfile'],
            }),
            getApiProfileByCard: build.query<
                GetApiProfileByCardApiResponse,
                GetApiProfileByCardApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/profile/by-card`,
                    params: { number: queryArg['number'] },
                }),
                providesTags: ['ForeignProfile'],
            }),
            getApiBlocksHierarchy: build.query<
                GetApiBlocksHierarchyApiResponse,
                GetApiBlocksHierarchyApiArg
            >({
                query: () => ({ url: `/api/blocks-hierarchy` }),
                providesTags: ['HierarchyItem'],
            }),
            getApiUser: build.query<GetApiUserApiResponse, GetApiUserApiArg>({
                query: () => ({ url: `/api/user` }),
                providesTags: ['User'],
            }),
        }),
        overrideExisting: false,
    })
export { injectedRtkApi as appApi }
export type GetAuthLoginApiResponse = unknown
export type GetAuthLoginApiArg = {
    returnUrl?: string
}
export type GetApiLoginApiResponse = unknown
export type GetApiLoginApiArg = {
    returnUrl?: string
}
export type GetAuthLogoutApiResponse = unknown
export type GetAuthLogoutApiArg = {
    returnUrl?: string
}
export type GetApiLogoutApiResponse = unknown
export type GetApiLogoutApiArg = {
    returnUrl?: string
}
export type PostAuthSigninCasSsoCallbackApiResponse = unknown
export type PostAuthSigninCasSsoCallbackApiArg = void
export type PostApiSigninCasSsoCallbackApiResponse = unknown
export type PostApiSigninCasSsoCallbackApiArg = void
export type GetApiFileByFileIdApiResponse = /** status 200 Success */ Blob
export type GetApiFileByFileIdApiArg = {
    /** Идентификатор файла */
    fileId: number
}
export type GetApiProfileByQrCodeApiResponse =
    /** status 200 Success */ ForeignProfileContract | /** status 204 No Content */ void
export type GetApiProfileByQrCodeApiArg = {
    /** Данные из QR кода */
    qrCodeData?: string
}
export type GetApiProfileByCardApiResponse =
    /** status 200 Success */ ForeignProfileContract | /** status 204 No Content */ void
export type GetApiProfileByCardApiArg = {
    /** Номер карты */
    number?: string
}
export type GetApiBlocksHierarchyApiResponse =
    /** status 200 Success */ HierarchyItemContract[]
export type GetApiBlocksHierarchyApiArg = void
export type GetApiUserApiResponse = /** status 200 Success */ UserContract
export type GetApiUserApiArg = void
export type ExceptionDataContract = {
    /** Название или короткое описание ошибки */
    name?: string | null
    /** Содержимое ошибки (полное описание) */
    value?: string | null
}
export type ExceptionErrorContract = {
    /** Текст сообщения */
    message?: string | null
}
export type ExceptionContract = {
    /** Тип ошибки, уникальный мнемонический идентификатор для каждого определенного типа ошибки */
    type?: string | null
    /** Данные об ошибке */
    data?: ExceptionDataContract[] | null
    /** Сообщения об ошибках */
    errors?: ExceptionErrorContract[] | null
}
export type GenderContract = 'Male' | 'Female'
export type CountryContract = {
    /** Наименование */
    name?: string | null
    /** Код страны */
    code?: string | null
}
export type AddressContract = {
    /** Регион */
    region?: string | null
    /** Район */
    district?: string | null
    /** Населенный пункт */
    city?: string | null
    /** Улица */
    street?: string | null
    /** Здание */
    building?: string | null
    /** Квартира */
    flat?: string | null
}
export type FileType = 'Unknown' | 'Pdf' | 'Jpeg' | 'Docx' | 'Png'
export type UploadedFileContract = {
    /** Идентификатор файла */
    id?: number
    /** Идентификатор файла превью */
    previewId?: number | null
    fileType?: FileType
    /** Размер файла в байтах */
    fileSize?: number | null
}
export type ArrivalDocumentWithFilesInfoContract = {
    /** Информация о файлах */
    files?: UploadedFileContract[] | null
    /** Кем выдан */
    issuer?: string | null
    /** Дата постановки на учет */
    registrationDate?: string | null
    /** Срок пребывания до */
    stayExpiresAt?: string | null
    /** Истёк ли срок действия документа */
    isExpired?: boolean
}
export type BiometryFingerprintDocumentWithFilesInfoContract = {
    /** Информация о файлах */
    files?: UploadedFileContract[] | null
    /** Серия документа */
    series?: string | null
    /** Номер документа */
    number?: string | null
    /** Кем выдан */
    issuer?: string | null
    /** Дата выдачи документа */
    issuedAt?: string | null
    /** Серия бланка */
    blankSeries?: string | null
    /** Номер бланка */
    blankNumber?: string | null
}
export type ForeignCardWithFilesInfoContract = {
    /** Информация о файлах */
    files?: UploadedFileContract[] | null
    /** Номер карты */
    cardNumber?: string | null
    /** Дата начала действия карты */
    issueDate?: string | null
    /** Дата окончания срока действия документа */
    expirationDate?: string | null
    /** Истёк ли срок действия документа */
    isExpired?: boolean
}
export type IdentityDocumentTypeContract =
    | 'InternationalPassport'
    | 'TemporaryResidenceStateless'
    | 'ResidencePermitStateless'
    | 'RefugeeCard'
    | 'RefugeeRecognitionCertificateRf'
    | 'TemporaryAsylumCertificateRf'
    | 'IdentificationCertificate'
    | 'NationalPassportUa'
    | 'PassportRf'
    | 'BirthCertificate'
    | 'ForeignBirthCertificate'
    | 'BirthCertificateRf'
    | 'IdentificationCertificateStateless'
    | 'TravelDocumentNonResident'
    | 'Other'
export type IdentityDocumentWithFilesInfoContract = {
    /** Информация о файлах */
    files?: UploadedFileContract[] | null
    identityDocumentType?: IdentityDocumentTypeContract
    /** Серия ДУЛ */
    series?: string | null
    /** Номер ДУЛ */
    number?: string | null
    /** Дата выдачи ДУЛ */
    issuedAt?: string
    /** Кем выдан ДУЛ */
    issuer?: string | null
    /** Признак, что источник персональных данных на русском языке */
    isRussianSource?: boolean | null
    /** Дата окончания срока действия документа */
    expirationDate?: string | null
    /** Истёк ли срок действия документа */
    isExpired?: boolean
}
export type MedicalDocumentTypeContract =
    | 'MedicalVerdict'
    | 'PsychiatristNarcologistVerdict'
    | 'HIVCertificate'
    | 'CovidCertificate'
export type MedicalDocumentWithFilesInfoContract = {
    /** Информация о файлах */
    files?: UploadedFileContract[] | null
    medicalDocumentType?: MedicalDocumentTypeContract
    /** Серия документа */
    series?: string | null
    /** Номер документа */
    number?: string | null
    /** Кем выдан документ */
    issuer?: string | null
    /** Дата выдачи */
    issuedAt?: string | null
    /** Дата окончания срока действия документа */
    expirationDate?: string | null
    /** Истёк ли срок действия документа */
    isExpired?: boolean
}
export type HivCertificateWithFilesInfoContract = {
    /** Информация о файлах */
    files?: UploadedFileContract[] | null
    medicalDocumentType?: MedicalDocumentTypeContract
    /** Серия документа */
    series?: string | null
    /** Номер документа */
    number?: string | null
    /** Кем выдан документ */
    issuer?: string | null
    /** Дата выдачи */
    issuedAt?: string | null
    /** Дата окончания срока действия документа */
    expirationDate?: string | null
    /** Истёк ли срок действия документа */
    isExpired?: boolean
}
export type PsychiatricReportWithFilesInfoContract = {
    /** Информация о файлах */
    files?: UploadedFileContract[] | null
    medicalDocumentType?: MedicalDocumentTypeContract
    /** Серия документа */
    series?: string | null
    /** Номер документа */
    number?: string | null
    /** Кем выдан документ */
    issuer?: string | null
    /** Дата выдачи */
    issuedAt?: string | null
    /** Дата окончания срока действия документа */
    expirationDate?: string | null
    /** Истёк ли срок действия документа */
    isExpired?: boolean
}
export type MigrationCardWithFilesInfoContract = {
    /** Информация о файлах */
    files?: UploadedFileContract[] | null
    /** Серия миграционной карты */
    series?: string | null
    /** Номер миграционной карты */
    number?: string | null
    /** Дата начала действия карты */
    startAt?: string | null
    /** Дата въезда */
    arrivedAt?: string | null
    /** КПП въезда */
    entryCheckpoint?: string | null
    /** Цель въезда */
    visitGoal?: string | null
}
export type TestingDocumentWithFilesInfoContract = {
    /** Информация о файлах */
    files?: UploadedFileContract[] | null
    /** Серия */
    series?: string | null
    /** Номер */
    number?: string | null
    /** Дата выдачи */
    issuedAt?: string | null
    /** Кем выдан */
    issuer?: string | null
    /** Тип документа */
    docType?: string | null
}
export type CertificateInnDocumentWithFilesInfoContract = {
    /** Информация о файлах */
    files?: UploadedFileContract[] | null
    /** Номер ИНН */
    innNumber?: string | null
    /** Серия */
    series?: string | null
    /** Номер */
    number?: string | null
    /** Кем выдан */
    issuer?: string | null
    /** Дата постановки на учет */
    registrationDate?: string | null
}
export type DmsDocumentIssueTypeContract = 'Exists' | 'InSystem' | 'OnForm'
export type DmsDocumentWithFilesInfoContract = {
    /** Информация о файлах */
    files?: UploadedFileContract[] | null
    /** Серия документа */
    series?: string | null
    /** Номер документа */
    number?: string | null
    /** Номер бланка строгой отчетности */
    blankNumber?: string | null
    /** Кем выдан */
    issuer?: string | null
    /** Дата выдачи */
    issuedAt?: string | null
    issueType?: DmsDocumentIssueTypeContract
    /** Дата окончания срока действия документа */
    expirationDate?: string | null
    /** Истёк ли срок действия документа */
    isExpired?: boolean
}
export type ResidencePermitDocumentWithFilesInfoContract = {
    /** Информация о файлах */
    files?: UploadedFileContract[] | null
    /** Серия документа */
    series?: string | null
    /** Номер документа */
    number?: string | null
    /** Серия бланка */
    blankSeries?: string | null
    /** Номер бланка */
    blankNumber?: string | null
    /** Кем выдан */
    issuer?: string | null
    /** Дата выдачи */
    issuedAt?: string | null
    /** Дата окончания срока действия документа */
    expirationDate?: string | null
    /** Истёк ли срок действия документа */
    isExpired?: boolean
}
export type TemporaryResidencePermitDocumentWithFilesInfoContract = {
    /** Информация о файлах */
    files?: UploadedFileContract[] | null
    /** Серия документа */
    series?: string | null
    /** Номер документа */
    number?: string | null
    /** Серия бланка */
    blankSeries?: string | null
    /** Номер бланка */
    blankNumber?: string | null
    /** Кем выдан */
    issuer?: string | null
    /** Дата выдачи */
    issuedAt?: string | null
    /** Дата окончания срока действия документа */
    expirationDate?: string | null
    /** Истёк ли срок действия документа */
    isExpired?: boolean
}
export type PreviousFioDocumentWithFilesInfoContract = {
    /** Информация о файлах */
    files?: UploadedFileContract[] | null
    /** Предыдущие ФИО */
    previousFio?: string | null
    /** Причина смены ФИО */
    reason?: string | null
    /** Год смены ФИО */
    year?: number | null
    /** Номер события */
    eventNumber?: string | null
}
export type DocumentValidityMonthContract = {
    /** Порядковый номер месяца действия документа */
    monthNumber?: string | null
    /** Дата окончания месяца действия */
    endDate?: string
    /** Месяц оплачен */
    isPaid?: boolean
    /** Оплата просрочена. Платеж еще не внесен, а период уже наступил */
    isPaymentExpired?: boolean
}
export type DocumentPaymentInfoContract = {
    /** Считается ли документ оплаченным на момент проверки */
    isPaid?: boolean
    /** Дата, до которой оплачен документ */
    paidUntil?: string | null
    /** Данные оплаты по месяцам действия документа */
    validityMonths?: DocumentValidityMonthContract[] | null
}
export type PatentDocumentWithFilesInfoContract = {
    /** Информация о файлах */
    files?: UploadedFileContract[] | null
    /** Серия документа */
    series?: string | null
    /** Номер документа */
    number?: string | null
    /** Серия бланка */
    blankSeries?: string | null
    /** Номер бланка */
    blankNumber?: string | null
    /** Кем выдан */
    issuer?: string | null
    /** Дата выдачи */
    issuedAt?: string | null
    /** Регион трудовой деятельности */
    region?: string | null
    paymentInfo?: DocumentPaymentInfoContract
    /** Дата окончания срока действия документа */
    expirationDate?: string | null
    /** Истёк ли срок действия документа */
    isExpired?: boolean
}
export type AppealStatusContract =
    | 'ServicesPending'
    | 'NeedReview'
    | 'NeedChanges'
    | 'SentMvd'
    | 'RejectedMvd'
    | 'Positive'
    | 'Negative'
    | 'ReadyForDelivery'
    | 'HandedApproval'
    | 'HandedDisapproval'
    | 'Annul'
export type AppealContract = {
    /** Номер обращения */
    number?: string | null
    /** Наименование основной услуги */
    mainService?: string | null
    /** Бизнес-статус для ЕЦП */
    status?: string | null
    statusCode?: AppealStatusContract
    /** Дата присвоения статуса */
    statusDate?: string | null
    /** Дата, когда было принято обращение */
    acceptanceDate?: string | null
    /** Дата создания */
    createDate?: string | null
    /** Признак "Активное заявление" */
    isActive?: boolean
}
export type NdflPaymentContract = {
    /** Серия патента, связанного с платежом */
    patentSeries?: string | null
    /** Номер патента, связанного с платежом */
    patentNumber?: string | null
    /** Уникальный идентификатор платежа */
    uniqueId?: string | null
    /** Сумма платежа */
    sum?: number
    /** Дата платежа */
    date?: string
    /** Признак подтверждения платежа в ММЦ */
    isConfirmed?: boolean
    /** Наименование банка */
    bankName?: string | null
}
export type ForeignProfileContract = {
    /** Уникальный идентификатор ИГ */
    uuid?: string | null
    /** Фамилия */
    surname?: string | null
    /** Имя */
    name?: string | null
    /** Отчество */
    patronymic?: string | null
    /** Фамилия (лат.) */
    surnameLatin?: string | null
    /** Имя (лат.) */
    nameLatin?: string | null
    /** Отчество (лат.) */
    patronymicLatin?: string | null
    /** Дата рождения */
    birthdate?: string | null
    gender?: GenderContract
    citizenship?: CountryContract
    /** Национальность */
    nationality?: string | null
    countryOfBirth?: CountryContract
    /** Место рождения */
    placeOfBirth?: string | null
    registrationAddress?: AddressContract
    residenceAddress?: AddressContract
    /** Адрес регистрации совпадает с адресом проживания */
    addressesMatched?: boolean | null
    /** Номер телефона */
    phoneNumber?: string | null
    /** Дополнительный номер телефона */
    additionalPhoneNumber?: string | null
    /** Адрес эл. почты */
    email?: string | null
    personalPhotoFile?: UploadedFileContract
    /** Признак нахождения ИГ в розыске */
    isWanted?: boolean | null
    /** Документы о прибытии */
    arrivalDocs?: ArrivalDocumentWithFilesInfoContract[] | null
    /** Документы, подтверждающие прохождение дактилоскопии и фотографирования */
    biometryFingerprintDocs?: BiometryFingerprintDocumentWithFilesInfoContract[] | null
    /** Карты ИГ */
    foreignCards?: ForeignCardWithFilesInfoContract[] | null
    /** Документы, удостоверяющие личность */
    identityDocs?: IdentityDocumentWithFilesInfoContract[] | null
    /** Медицинские документы ИГ: медицинское заключение */
    medicalReportDocs?: MedicalDocumentWithFilesInfoContract[] | null
    /** Медицинские документы ИГ: сертификат об отсутствии ВИЧ-инфекции */
    hivCertificateDocs?: HivCertificateWithFilesInfoContract[] | null
    /** Медицинские документы ИГ: заключение врача психиатра-нарколога */
    psychiatricReportDocs?: PsychiatricReportWithFilesInfoContract[] | null
    /** Миграционные карты ИГ */
    migrationCards?: MigrationCardWithFilesInfoContract[] | null
    /** Список документов, подтверждающий владение русским языком */
    testingDocs?: TestingDocumentWithFilesInfoContract[] | null
    /** Список документов "Свидетельство о постановке на учет в налоговом органе (ИНН)" */
    innDocs?: CertificateInnDocumentWithFilesInfoContract[] | null
    /** Список полисов ДМС */
    dmsDocs?: DmsDocumentWithFilesInfoContract[] | null
    /** Список документов - Вид на жительство */
    residencePermitDocs?: ResidencePermitDocumentWithFilesInfoContract[] | null
    /** Список документов - Разрешение на временное проживание */
    temporaryResidencePermitDocs?:
        | TemporaryResidencePermitDocumentWithFilesInfoContract[]
        | null
    /** Документы, подтверждающие смену ФИО ИГ */
    previousFioDocs?: PreviousFioDocumentWithFilesInfoContract[] | null
    /** Патенты */
    patentDocs?: PatentDocumentWithFilesInfoContract[] | null
    /** Данные обращений */
    appeals?: AppealContract[] | null
    /** Данные платежей */
    payments?: NdflPaymentContract[] | null
}
export type HierarchyItemTypesContract =
    | 'Documents'
    | 'Contacts'
    | 'Appeals'
    | 'Identity'
    | 'MigrationDoc'
    | 'Labour'
    | 'PersonalInfo'
    | 'IdentityDocument'
    | 'MigrationCard'
    | 'ArrivalNotification'
    | 'MedicalReport'
    | 'HivCertificate'
    | 'PsychiatricReport'
    | 'NameChangeDocument'
    | 'FingerprintRegistrationDocument'
    | 'Inn'
    | 'DmsPolicy'
    | 'ResidencyPermit'
    | 'TemporaryResidencePermit'
    | 'ElectronicCitizenCard'
    | 'Testing'
export type HierarchyItemContract = {
    /** Уникальный идентификатор */
    id?: number
    /** Идентификатор родителя */
    parentId?: number | null
    itemName?: HierarchyItemTypesContract
    /** Порядок элемента в иерархии */
    sortOrder?: number
    /** Блок скрытый */
    isHidden?: boolean
}
export type AccountPermissionContract =
    | 'Unknown'
    | 'BasicView'
    | 'DocsView'
    | 'LaborView'
    | 'PersonaView'
    | 'ContactView'
    | 'AppealView'
    | 'DocsAllView'
    | 'ScansAllView'
    | 'IdentityDocumentView'
    | 'IdentityDocumentScanView'
    | 'MigrationCardView'
    | 'MigrationCardScanView'
    | 'ArrivalNotificationView'
    | 'ArrivalNotificationScanView'
    | 'MedicalReportView'
    | 'MedicalReportScanView'
    | 'HivCertificateView'
    | 'HivCertificateScanView'
    | 'PsychiatricReportView'
    | 'PsychiatricReportScanView'
    | 'PreviousFioView'
    | 'PreviousFioScanView'
    | 'BiometryFingerprintDocumentView'
    | 'BiometryFingerprintDocumentScanView'
    | 'InnView'
    | 'InnScanView'
    | 'DmsView'
    | 'DmsScanView'
    | 'ResidencePermitView'
    | 'ResidencePermitScanView'
    | 'TemporaryResidencePermitView'
    | 'TemporaryResidencePermitScanView'
    | 'ForeignCardView'
    | 'ForeignCardScanView'
    | 'TestingView'
    | 'TestingScanView'
export type UserContract = {
    /** Уникальный идентификатор пользователя */
    userId?: string | null
    /** Логин пользователя */
    login?: string | null
    /** Фамилия пользователя */
    surname?: string | null
    /** Имя пользователя */
    name?: string | null
    /** Отчество пользователя */
    patronymic?: string | null
    /** Роль пользователя */
    role?: string | null
    /** Разрешения пользователя */
    permissions?: AccountPermissionContract[] | null
}
export const {
    useGetAuthLoginQuery,
    useLazyGetAuthLoginQuery,
    useGetApiLoginQuery,
    useLazyGetApiLoginQuery,
    useGetAuthLogoutQuery,
    useLazyGetAuthLogoutQuery,
    useGetApiLogoutQuery,
    useLazyGetApiLogoutQuery,
    usePostAuthSigninCasSsoCallbackMutation,
    usePostApiSigninCasSsoCallbackMutation,
    useGetApiFileByFileIdQuery,
    useLazyGetApiFileByFileIdQuery,
    useGetApiProfileByQrCodeQuery,
    useLazyGetApiProfileByQrCodeQuery,
    useGetApiProfileByCardQuery,
    useLazyGetApiProfileByCardQuery,
    useGetApiBlocksHierarchyQuery,
    useLazyGetApiBlocksHierarchyQuery,
    useGetApiUserQuery,
    useLazyGetApiUserQuery,
} = injectedRtkApi
