import throttle from 'lodash/throttle'
import { Icon } from 'mmc-react-icons'
import { TAILWIND_THEME_COLORS } from 'mmc-react-theme'
import { useCallback, useEffect, useState } from 'react'

const THROTTLE_TIME = 200

export function useScrollOutsideBorder(heightBorder = window.innerHeight) {
    const [isScrollOutsideBorders, setScrollOutsideBorders] = useState(false)

    useEffect(() => {
        const handleScroll = throttle(() => {
            setScrollOutsideBorders(window.scrollY > heightBorder)
        }, THROTTLE_TIME)

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
            handleScroll.cancel()
        }
    }, [heightBorder])

    const scrollToTop = useCallback(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }, [])

    return {
        isScrollOutsideBorders,
        scrollToTopButton: isScrollOutsideBorders ? (
            <Icon
                svgType="SvgIconArrowAngleTopRound10X6"
                size={12}
                pathFill={TAILWIND_THEME_COLORS['color-neutral-1']}
                iconContainerClassName="opacity-20 hover:opacity-40 fixed bottom-8 right-8 z-10 rounded"
                iconContainerBg="bg-color-black"
                iconContainerPreset="44"
                onClick={scrollToTop}
            />
        ) : null,
    }
}
