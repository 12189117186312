import { ConfigProvider } from 'antd'

import { BrowserRouter } from 'react-router-dom'
import { AppInitProps } from './AppInit.types'
import { FC } from 'react'

import ru_RU from 'antd/locale/ru_RU'

import 'dayjs/locale/ru'

import { Provider } from 'react-redux'
import { store } from 'store'
import { Empty, Preloader, useBlockBodyScroll } from 'mmc-react-shared'

import '../../../node_modules/mmc-react-shared/dist/components.css'
import '../../../node_modules/mmc-react-shared/dist/Button.css'
import '../../../node_modules/mmc-react-shared/dist/ant-design.css'
import '../../../node_modules/mmc-react-shared/dist/index.css'

import 'tailwindcss/tailwind.css'
import '../../index.css'
import { AuthCheck } from 'components/business/AuthCheck'
import { useOffline } from 'hooks/useOffline'
import { Offline } from 'components/business/Offline'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import { APP_INIT_LOCALIZATION } from './AppInit.localization'

dayjs.locale('ru', {
    name: 'ru',
    monthsShort: APP_INIT_LOCALIZATION.MONTHS_SHORT,
})

const renderEmpty = () => <Empty />

export const AppInit: FC<AppInitProps> = ({ children, withAuthCheck = true }) => {
    const { offline, updateConnectionStatus } = useOffline()

    const app = withAuthCheck ? (
        <AuthCheck>
            <BrowserRouter>{children}</BrowserRouter>
        </AuthCheck>
    ) : (
        <BrowserRouter>{children}</BrowserRouter>
    )

    useBlockBodyScroll(offline)

    return (
        <>
            <ConfigProvider locale={ru_RU} renderEmpty={renderEmpty}>
                <Provider store={store}>{app}</Provider>
            </ConfigProvider>

            <Preloader
                isError={offline}
                error={<Offline updateConnectionStatus={updateConnectionStatus} />}
            />
        </>
    )
}
