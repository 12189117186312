import { useBreakpoint, useElementVisible } from 'mmc-react-shared'
import { Typography } from 'mmc-react-theme'
import React, { FC } from 'react'

import styles from './PersonalInformation.module.css'
import classNames from 'classnames'
import { PERSONAL_INFORMATION_LOCALIZATION } from './PersonalInformation.localization'
import { PersonInfromationPhotoProps } from './PersonalInformation.types'
import { TEST_IDS } from 'consts/tests.consts'

export const PersonInformationPhoto: FC<PersonInfromationPhotoProps> = React.memo(
    ({ photoSrc, compactView }) => {
        const { elementVisible, handleHideElement } = useElementVisible(true)

        const isTablet = useBreakpoint('Tablet')

        const classNameTabletPhoto = classNames(
            styles.imgTablet,
            compactView && styles.imgTabletCompact
        )

        const classNamePhoto = isTablet ? classNameTabletPhoto : styles.imgMobile

        return photoSrc && elementVisible ? (
            <img
                src={photoSrc}
                className={classNamePhoto}
                onError={handleHideElement}
                data-testid={TEST_IDS.PERSONAL_INFORMATION_PHOTO}
            />
        ) : (
            <Typography
                className={classNames(classNamePhoto, styles.emptyProfilePhoto)}
                type="text-small"
                textColor="color-neutral-13"
                weight="500"
                dataTestId={TEST_IDS.PERSONAL_INFORMATION_PHOTO}
            >
                {PERSONAL_INFORMATION_LOCALIZATION.EMPTY_PHOTO_PLACEHOLDER}
            </Typography>
        )
    }
)
