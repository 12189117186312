import React, { FC } from 'react'
import { CitizenDocumentsProps, ShortDocumentInfo } from './CitizenDocuments.types'
import { FieldView } from 'components/controls/FieldView'
import { useNavigator } from 'hooks/useNavigator'
import { useParams } from 'react-router-dom'
import { CitizenCardUrlParams } from 'types/business.types'
import { CITIZEN_CARD_LOCALIZATION } from 'pages/CitizenCard/CitizenCard.localization'
import { CollapseItem } from 'components/business/CollapseItem'
import { useScrollTo } from 'hooks/useScrollTo'
import { SCROLL_KEYS } from 'consts/common.consts'

export const CitizenDocuments: FC<CitizenDocumentsProps> = React.memo(({ documents }) => {
    const { uidOrCard } = useParams<CitizenCardUrlParams>()

    const { goToCitizenCardDocument } = useNavigator()

    useScrollTo(SCROLL_KEYS.document)

    const renderDocument = (document: ShortDocumentInfo) => {
        if (!document.type) return

        const handleClick = () => {
            goToCitizenCardDocument(uidOrCard, document.type)
        }

        return (
            <CollapseItem
                key={document.type}
                onClick={handleClick}
                dataScrollAttrKey={SCROLL_KEYS.document}
                dataScrollAttrValue={document.type}
                isActive={!!document.value}
                isAttention={document.attention}
            >
                <FieldView
                    label={document.name}
                    value={document.value || CITIZEN_CARD_LOCALIZATION.DOCUMENT_NOT_EXIST}
                    className="!mb-0 overflow-wrap-anywhere"
                />
            </CollapseItem>
        )
    }

    return documents.map(renderDocument)
})
