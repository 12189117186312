import { memo } from 'react'

import { useParams } from 'react-router-dom'

import { useScrollTo } from 'hooks/useScrollTo'
import { useNavigator } from 'hooks/useNavigator'

import { CitizenCardUrlParams } from 'types/business.types'
import { PatentDocumentWithFilesInfoContract } from 'api/generator/appApi'

import { CollapseItem } from 'components/business/CollapseItem'

import { CitizenPatentsProps } from './CitizenPatents.types'

import { CITIZEN_PATENTS_LOCALIZATION } from './CItizenPatents.localization'
import { getSeriesAndNumber } from 'utils/common.utils'
import { SCROLL_KEYS } from 'consts/common.consts'
import { formatDate, Label } from 'mmc-react-shared'
import { Typography } from 'mmc-react-theme'

export const CitizenPatents: React.FC<CitizenPatentsProps> = memo(({ patentDocs }) => {
    const { uidOrCard } = useParams<CitizenCardUrlParams>()

    const { goToCitizenPatent } = useNavigator()

    useScrollTo(SCROLL_KEYS.patent)

    const renderWorkData = (patentDoc: PatentDocumentWithFilesInfoContract) => {
        const patentSeriesNumber = getSeriesAndNumber(patentDoc, '-')

        const handleClick = () => {
            goToCitizenPatent(uidOrCard, patentSeriesNumber)
        }

        const isPatentPaid = patentDoc.paymentInfo?.isPaid

        return (
            <CollapseItem
                key={patentDoc.blankNumber}
                onClick={handleClick}
                dataScrollAttrKey={SCROLL_KEYS.patent}
                dataScrollAttrValue={patentSeriesNumber}
                isActive={!!patentDoc}
                isAttention={!isPatentPaid}
            >
                <div className="overflow-wrap-anywhere">
                    <Label text={patentDoc.region || ''} textColor="color-neutral-19" />

                    <Typography textColor="color-neutral-13" className="pt-2">
                        {CITIZEN_PATENTS_LOCALIZATION.PATENT_PAYMENT_INFO(patentDoc)}{' '}
                        <Typography
                            inline
                            textColor={isPatentPaid ? 'color-neutral-13' : 'color-red-9'}
                            text={formatDate(patentDoc.paymentInfo?.paidUntil)}
                        />
                    </Typography>
                </div>
            </CollapseItem>
        )
    }

    return patentDocs?.map(renderWorkData)
})
