import { memo } from 'react'
import { LegendLabelProps } from './LegendLabel.types'
import styles from './LegendLabel.module.css'
import { Typography } from 'mmc-react-theme'
import classNames from 'classnames'

export const LegendLabel: React.FC<LegendLabelProps> = memo(({ type, text }) => {
    return (
        <div className={styles.root}>
            <div className={classNames(styles.legendColor, styles[type], 'm-0.5 mr-2')} />

            <Typography textColor="color-neutral-13">{text}</Typography>
        </div>
    )
})
