import { FC, memo } from 'react'
import classNames from 'classnames'
import styles from './ScaleItem.module.css'
import { Col } from 'antd'
import dayjs from 'dayjs'
import { Typography } from 'mmc-react-theme'
import { ScaleItemProps } from './ScaleItem.types'

export const ScaleItem: FC<ScaleItemProps> = memo(({ payment }) => {
    const isExpired = payment.isPaymentExpired && !payment.isPaid

    return (
        <Col
            xs={8}
            md={4}
            className={classNames(
                isExpired && styles.periodExpired,
                payment.isPaid && styles.periodPaid
            )}
        >
            <Typography
                weight="500"
                textColor="color-neutral-14"
                className={classNames(styles.counter, 'p-1')}
            >
                {payment.monthNumber}
            </Typography>

            <Typography
                textColor="color-neutral-13"
                className={classNames(styles.endDate, 'p-1')}
            >
                {dayjs(payment.endDate).format('DD MMM')}
            </Typography>
        </Col>
    )
})
