import { memo } from 'react'
import { PaymentInformationProps } from './PaymentInformation.types'
import { PAYMENT_INFORMATION_LOCALIZATION } from './PaymentInformation.localization'

import styles from './PaymentInformation.module.css'
import classNames from 'classnames'
import { formatDate, useBreakpoint } from 'mmc-react-shared'
import { NdflPaymentContract } from 'api/generator/appApi'
import { PLACEHOLDERS_LOCALIZATIONS } from 'localization/placeholders.localization'
import { Typography } from 'mmc-react-theme'

export const PaymentInformation: React.FC<PaymentInformationProps> = memo(
    ({ payments }) => {
        const isTablet = useBreakpoint('Tablet')

        if (!payments?.length) {
            return (
                <div className="tablet:p-3 py-3">
                    <Typography textColor="color-neutral-12">
                        {PLACEHOLDERS_LOCALIZATIONS.MISSING_DATA}
                    </Typography>
                </div>
            )
        }

        const renderPayments = (payment: NdflPaymentContract) => {
            const formatedPaymentDate = formatDate(payment.date)
            return (
                <div
                    key={payment.uniqueId}
                    className={classNames(
                        styles.payment,
                        'flex justify-between py-5 items-center'
                    )}
                >
                    <div>
                        {isTablet && (
                            <>
                                <Typography
                                    text={payment.bankName}
                                    weight="500"
                                    textColor="color-neutral-19"
                                    className="mb-2"
                                />

                                <Typography
                                    text={`${formatedPaymentDate} ● ${payment.uniqueId}`}
                                    textColor="color-neutral-12"
                                />
                            </>
                        )}

                        {!isTablet && (
                            <>
                                <Typography
                                    text={payment.bankName}
                                    weight="500"
                                    textColor="color-neutral-19"
                                    className="mb-2"
                                />

                                <Typography
                                    text={formatedPaymentDate}
                                    className="mb-2"
                                    textColor="color-neutral-12"
                                />

                                <Typography
                                    text={payment.uniqueId}
                                    textColor="color-neutral-12"
                                    className="mb-2"
                                />
                            </>
                        )}
                    </div>

                    <Typography className="flex-shrink-0">{`${payment.sum?.toLocaleString()} ${
                        PAYMENT_INFORMATION_LOCALIZATION.RUB
                    }`}</Typography>
                </div>
            )
        }

        const totalSum = payments
            .reduce((prev, cur) => prev + (cur?.sum || 0), 0)
            .toLocaleString()

        return (
            <>
                <div>{payments?.map(renderPayments)}</div>

                <Typography className="flex py-3 justify-end">
                    {PAYMENT_INFORMATION_LOCALIZATION.TOTAL_SUM}
                    <Typography weight="500">
                        &nbsp;{`${totalSum} ${PAYMENT_INFORMATION_LOCALIZATION.RUB}`}
                    </Typography>
                </Typography>
            </>
        )
    }
)
